import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public isAdmin: boolean = false;
  public token: any;

  constructor(
     private router: Router) {
  }

  ngOnInit(): void {
  }

}

