<div class="sidebar">
    <ul class="sidebar-nav">
        <li class="sidebar-link" routerLink="/dashboard/adminLogin" routerLinkActive="active" *ngIf="!isAdmin">
            <div class="icon-box">
                <i class="bi-caret-right-fill"></i>
            </div>
            Admin
        </li>

        <li class="sidebar-link" routerLink="/dashboard/maindashboard" routerLinkActive="active" *ngIf="isAdmin">
            <div class="icon-box">
                <i class="bi-caret-right-fill"></i>
            </div>
            Application
        </li>
        <li class="sidebar-link" routerLink="/dashboard/api" routerLinkActive="active" *ngIf="isAdmin">
            <div class="icon-box">
                <i class="bi-caret-right-fill"></i>
            </div>
            API
        </li>
        <!-- <li class="sidebar-link" routerLinkActive="active" (click)="navigateTo()">
            <div class="icon-box">
                <i class="bi-caret-right-fill"></i>
            </div>
            User
        </li> -->
    </ul>



</div>