import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FhirServiceService {

  constructor(private http: HttpClient) { }

  public resourceList = [
    {
      "id": 15,
      "module": "Allergy",
      "description": "Harmful or undesired physiological responses associated with exposure to a substance"
    },
    {
      "id": 16,
      "module": "Appointments",
      "description": "Information on appointments of patient"
    },
    {
      "id": 22,
      "module": "Audit Trail",
      "description": "Audit trails of patient"
    },
    {
      "id": 27,
      "module": "Charges, Payments and adjustment",
      "description": "Retrieves billing charges of patient"
    },
    {
      "id": 9,
      "module": "Demographic",
      "description": "Data used to categorize individuals for identification, records matching, and other purposes"
    },
    {
      "id": 20,
      "module": "Denials",
      "description": "Retrieves denials of Patient"
    },
    {
      "id": 25,
      "module": "Eligibility",
      "description": "Retrieves eligibility of patient"
    },
    {
      "id": 11,
      "module": "Guarantor",
      "description": "A responsible party for patients bills"
    },
    {
      "id": 6,
      "module": "History",
      "description": "Current and past conditions and observations of the patient"
    },
    {
      "id": 3,
      "module": "Immunization",
      "description": "Record of vaccine administration"
    },
    {
      "id": 19,
      "module": "Insurances",
      "description": "Data related to an individuals insurance coverage for health care"
    },
    {
      "id": 28,
      "module": "Medical devices or equipment",
      "description": "An instrument, machine, appliance, implant, software or other article intended to be used for a medical purpose"
    },
    {
      "id": 24,
      "module": "Medication",
      "description": "Pharmacologic agents used in the diagnosis, cure, mitigation, treatment, or prevention of disease"
    },
    {
      "id": 4,
      "module": "OB Vitals",
      "description": "Pregnancy details of patient"
    },
    {
      "id": 14,
      "module": "Other Care Teams",
      "description": "Information on a person who participates or is expected to participate in the care of a patient"
    },
    {
      "id": 2,
      "module": "Patient Alert",
      "description": "Alert messages for patient"
    },
    {
      "id": 30,
      "module": "Patient Cases",
      "description": "Retrieves patient cases - prior authorization master for patient"
    },
    {
      "id": 7,
      "module": "Patient-Provider Messages",
      "description": "Retrieves provider messages"
    },
    {
      "id": 26,
      "module": "Pharmacy",
      "description": "Patients pharmacy details"
    },
    {
      "id": 17,
      "module": "Primary Care Physician",
      "description": "provider acts as the first contact and principal point of continuing care for patients within a healthcare system"
    },
    {
      "id": 13,
      "module": "Prior Authorization",
      "description": "Retrieves prior authorization details for patient"
    },
    {
      "id": 29,
      "module": "Problems",
      "description": "Condition, diagnosis, or reason for seeking medical attention"
    },
    {
      "id": 10,
      "module": "Referrals",
      "description": "Request from one health professional to another health professional to diagnose patient"
    },
    {
      "id": 12,
      "module": "Refunds",
      "description": "Retrieves refunds of patient"
    },
    {
      "id": 21,
      "module": "Reserves",
      "description": "Retrieves reserves for patient"
    },
    {
      "id": 5,
      "module": "Vitals",
      "description": "Physiologic measurements of a patient that indicate the status of the bodys life sustaining functions"
    }
  ]

  ehiexportdocs = [
    {
      "id": 1,
      "module": "Allergy",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 2,
      "module": "Allergy",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 3,
      "module": "Allergy",
      "field": "VisitID",
      "type": "numeric",
      "description": "Visit Identifier"
    },
    {
      "id": 4,
      "module": "Allergy",
      "field": "VisitDate",
      "type": "date",
      "description": "Visit Date"
    },
    {
      "id": 5,
      "module": "Allergy",
      "field": "HistoryCategory",
      "type": "text",
      "description": "History Category"
    },
    {
      "id": 6,
      "module": "Allergy",
      "field": "AllergyID",
      "type": "numeric",
      "description": "Allergy Identifier"
    },
    {
      "id": 7,
      "module": "Allergy",
      "field": "HistoryItem",
      "type": "text",
      "description": "History Item"
    },
    {
      "id": 8,
      "module": "Allergy",
      "field": "Comments",
      "type": "text",
      "description": "Comments"
    },
    {
      "id": 9,
      "module": "Allergy",
      "field": "Reaction",
      "type": "text",
      "description": "History Reaction"
    },
    {
      "id": 10,
      "module": "Allergy",
      "field": "HistoryStatus",
      "type": "text",
      "description": "History Status"
    },
    {
      "id": 11,
      "module": "Allergy",
      "field": "DrugID",
      "type": "numeric",
      "description": "Drug Identifier"
    },
    {
      "id": 12,
      "module": "Allergy",
      "field": "MedicalConditionId",
      "type": "numeric",
      "description": "Medical Condition Identifier"
    },
    {
      "id": 13,
      "module": "Allergy",
      "field": "DrugName",
      "type": "text",
      "description": "Drug Name"
    },
    {
      "id": 14,
      "module": "Allergy",
      "field": "Dosage",
      "type": "text",
      "description": "Dosage"
    },
    {
      "id": 15,
      "module": "Allergy",
      "field": "NDCCode",
      "type": "text",
      "description": "NDC Code"
    },
    {
      "id": 16,
      "module": "Allergy",
      "field": "DrugDatabaseID",
      "type": "numeric",
      "description": "Drug Database Identifier"
    },
    {
      "id": 17,
      "module": "Allergy",
      "field": "DOEAllergy",
      "type": "text",
      "description": "Date of Estimated Allergy"
    },
    {
      "id": 18,
      "module": "Allergy",
      "field": "ConceptID",
      "type": "numeric",
      "description": "Concept Identifier"
    },
    {
      "id": 19,
      "module": "Allergy",
      "field": "DescriptionID",
      "type": "numeric",
      "description": "Description Identifier"
    },
    {
      "id": 20,
      "module": "Allergy",
      "field": "SnoMedID",
      "type": "numeric",
      "description": "Snomed Identifier"
    },
    {
      "id": 21,
      "module": "Allergy",
      "field": "Description",
      "type": "text",
      "description": "Description"
    },
    {
      "id": 22,
      "module": "Allergy",
      "field": "TranID1",
      "type": "text",
      "description": "Transition Identifier 1"
    },
    {
      "id": 23,
      "module": "Allergy",
      "field": "TranID2",
      "type": "text",
      "description": "Transition Identifier 2"
    },
    {
      "id": 24,
      "module": "Allergy",
      "field": "TranID3",
      "type": "text",
      "description": "Transition Identifier 3"
    },
    {
      "id": 25,
      "module": "Allergy",
      "field": "ICD9",
      "type": "text",
      "description": "ICD9"
    }, {
      "id": 26,
      "module": "Appointments",
      "field": "nPatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 27,
      "module": "Appointments",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 28,
      "module": "Appointments",
      "field": "EncounterID",
      "type": "text",
      "description": "Encounter Identifier"
    },
    {
      "id": 29,
      "module": "Appointments",
      "field": "EncounterDate",
      "type": "date",
      "description": "Encounter Date"
    },
    {
      "id": 30,
      "module": "Appointments",
      "field": "CreatedDate",
      "type": "date",
      "description": "Created Date"
    },
    {
      "id": 31,
      "module": "Appointments",
      "field": "ModifiedDate",
      "type": "date",
      "description": "Modified Date"
    },
    {
      "id": 32,
      "module": "Appointments",
      "field": "ProviderID",
      "type": "numeric",
      "description": "Provider Identifier"
    },
    {
      "id": 33,
      "module": "Appointments",
      "field": "ProviderName",
      "type": "text",
      "description": "Provider Name"
    },
    {
      "id": 34,
      "module": "Appointments",
      "field": "AppDate",
      "type": "date",
      "description": "Appointment Date"
    },
    {
      "id": 35,
      "module": "Appointments",
      "field": "AppTime",
      "type": "date",
      "description": "Appointment Time"
    },
    {
      "id": 36,
      "module": "Appointments",
      "field": "AppType",
      "type": "text",
      "description": "Appointment Type"
    },
    {
      "id": 37,
      "module": "Appointments",
      "field": "ApptDuration",
      "type": "date",
      "description": "Appointment Duration"
    },
    {
      "id": 38,
      "module": "Appointments",
      "field": "AppNote",
      "type": "text",
      "description": "Appointment Notes"
    },
    {
      "id": 39,
      "module": "Appointments",
      "field": "Status",
      "type": "numeric",
      "description": "Appointment Status"
    },
    {
      "id": 40,
      "module": "Appointments",
      "field": "sLocationName",
      "type": "text",
      "description": "Clinic Location Name"
    }, {
      "id": 41,
      "module": "Audit Trail",
      "field": "ActivityDateTime",
      "type": "date",
      "description": "Activity Date Time"
    },
    {
      "id": 42,
      "module": "Audit Trail",
      "field": "ActivityCategory",
      "type": "text",
      "description": "Activity Category"
    },
    {
      "id": 43,
      "module": "Audit Trail",
      "field": "Description",
      "type": "text",
      "description": "Description"
    },
    {
      "id": 44,
      "module": "Audit Trail",
      "field": "MachineName",
      "type": "text",
      "description": "Machine Name"
    },
    {
      "id": 45,
      "module": "Audit Trail",
      "field": "SoftwareComponent",
      "type": "text",
      "description": "Software Component"
    },
    {
      "id": 46,
      "module": "Audit Trail",
      "field": "Outcome",
      "type": "text",
      "description": "Outcome"
    },
    {
      "id": 47,
      "module": "Audit Trail",
      "field": "ActivityModule",
      "type": "text",
      "description": "Activity Module"
    },
    {
      "id": 48,
      "module": "Audit Trail",
      "field": "ActivityType",
      "type": "text",
      "description": "Activity Type"
    },
    {
      "id": 49,
      "module": "Audit Trail",
      "field": "UserName",
      "type": "text",
      "description": "User Name"
    }, {
      "id": 50,
      "module": "Charges, Payments and adjustment",
      "field": "ClaimNumber",
      "type": "text",
      "description": "Claim Number"
    },
    {
      "id": 51,
      "module": "Charges, Payments and adjustment",
      "field": "Patient",
      "type": "text",
      "description": "Patient Name"
    },
    {
      "id": 52,
      "module": "Charges, Payments and adjustment",
      "field": "DOS",
      "type": "date",
      "description": "Date of Service"
    },
    {
      "id": 53,
      "module": "Charges, Payments and adjustment",
      "field": "CPT",
      "type": "text",
      "description": "CPT Code"
    },
    {
      "id": 54,
      "module": "Charges, Payments and adjustment",
      "field": "DX1",
      "type": "text",
      "description": "Diagnosis 1"
    },
    {
      "id": 55,
      "module": "Charges, Payments and adjustment",
      "field": "DX2",
      "type": "text",
      "description": "Diagnosis 2"
    },
    {
      "id": 56,
      "module": "Charges, Payments and adjustment",
      "field": "DX3",
      "type": "text",
      "description": "Diagnosis 3"
    },
    {
      "id": 57,
      "module": "Charges, Payments and adjustment",
      "field": "DX4",
      "type": "text",
      "description": "Diagnosis 4"
    },
    {
      "id": 58,
      "module": "Charges, Payments and adjustment",
      "field": "M1",
      "type": "text",
      "description": "Modifier 1"
    },
    {
      "id": 59,
      "module": "Charges, Payments and adjustment",
      "field": "M2",
      "type": "text",
      "description": "Modifier 2"
    },
    {
      "id": 60,
      "module": "Charges, Payments and adjustment",
      "field": "Provider",
      "type": "text",
      "description": "Provider Name"
    },
    {
      "id": 61,
      "module": "Charges, Payments and adjustment",
      "field": "CloseDate",
      "type": "date",
      "description": "Close Date"
    },
    {
      "id": 62,
      "module": "Charges, Payments and adjustment",
      "field": "Amount",
      "type": "numeric",
      "description": "Amount"
    },
    {
      "id": 63,
      "module": "Charges, Payments and adjustment",
      "field": "Adjustment",
      "type": "numeric",
      "description": "Adjustment"
    },
    {
      "id": 64,
      "module": "Charges, Payments and adjustment",
      "field": "PatientPayment",
      "type": "numeric",
      "description": "Patient Payment"
    },
    {
      "id": 65,
      "module": "Charges, Payments and adjustment",
      "field": "InsurancePayment",
      "type": "numeric",
      "description": "Insurance Payment"
    },
    {
      "id": 66,
      "module": "Charges, Payments and adjustment",
      "field": "Party",
      "type": "text",
      "description": "Party"
    },
    {
      "id": 67,
      "module": "Charges, Payments and adjustment",
      "field": "InsurancePending",
      "type": "numeric",
      "description": "Insurance Pending"
    },
    {
      "id": 68,
      "module": "Charges, Payments and adjustment",
      "field": "PatientDue",
      "type": "numeric",
      "description": "Patient Due"
    },
    {
      "id": 69,
      "module": "Charges, Payments and adjustment",
      "field": "BadDebt",
      "type": "numeric",
      "description": "Bad Debt"
    },
    {
      "id": 70,
      "module": "Demographic",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 71,
      "module": "Demographic",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 72,
      "module": "Demographic",
      "field": "Prefix",
      "type": "text",
      "description": "Prefix"
    },
    {
      "id": 73,
      "module": "Demographic",
      "field": "FirstName",
      "type": "text",
      "description": "Patient First Name"
    },
    {
      "id": 74,
      "module": "Demographic",
      "field": "MiddleName",
      "type": "text",
      "description": "Patient Middle Name"
    },
    {
      "id": 75,
      "module": "Demographic",
      "field": "LastName",
      "type": "text",
      "description": "Patient Last Name"
    },
    {
      "id": 76,
      "module": "Demographic",
      "field": "SSN",
      "type": "text",
      "description": "SSN"
    },
    {
      "id": 77,
      "module": "Demographic",
      "field": "DateOfBirth",
      "type": "Date",
      "description": "Date Of Birth"
    },
    {
      "id": 78,
      "module": "Demographic",
      "field": "BirthTime",
      "type": "date",
      "description": "Birth Time"
    },
    {
      "id": 79,
      "module": "Demographic",
      "field": "Gender",
      "type": "text",
      "description": "Gender"
    },
    {
      "id": 80,
      "module": "Demographic",
      "field": "MaritalStatus",
      "type": "text",
      "description": "Marital Status"
    },
    {
      "id": 81,
      "module": "Demographic",
      "field": "Race",
      "type": "text",
      "description": "Race"
    },
    {
      "id": 82,
      "module": "Demographic",
      "field": "HandDominance",
      "type": "text",
      "description": "Hand Dominance"
    },
    {
      "id": 83,
      "module": "Demographic",
      "field": "Languages",
      "type": "text",
      "description": "Languages"
    },
    {
      "id": 84,
      "module": "Demographic",
      "field": "Ethnicity",
      "type": "text",
      "description": "Ethnicity"
    },
    {
      "id": 85,
      "module": "Demographic",
      "field": "sPatientStatus",
      "type": "text",
      "description": "Patient Status"
    },
    {
      "id": 86,
      "module": "Demographic",
      "field": "AddressLine1",
      "type": "text",
      "description": "Address Line 1"
    },
    {
      "id": 87,
      "module": "Demographic",
      "field": "AddressLine2",
      "type": "text",
      "description": "Address Line 2"
    },
    {
      "id": 88,
      "module": "Demographic",
      "field": "City",
      "type": "text",
      "description": "City"
    },
    {
      "id": 89,
      "module": "Demographic",
      "field": "State",
      "type": "text",
      "description": "State"
    },
    {
      "id": 90,
      "module": "Demographic",
      "field": "Country",
      "type": "text",
      "description": "Country"
    },
    {
      "id": 91,
      "module": "Demographic",
      "field": "ZIP",
      "type": "text",
      "description": "ZIP"
    },
    {
      "id": 92,
      "module": "Demographic",
      "field": "County",
      "type": "text",
      "description": "County"
    },
    {
      "id": 93,
      "module": "Demographic",
      "field": "Phone",
      "type": "text",
      "description": "Phone"
    },
    {
      "id": 94,
      "module": "Demographic",
      "field": "Mobile",
      "type": "text",
      "description": "Mobile"
    },
    {
      "id": 95,
      "module": "Demographic",
      "field": "Email",
      "type": "text",
      "description": "Email"
    },
    {
      "id": 96,
      "module": "Demographic",
      "field": "FAX",
      "type": "text",
      "description": "FAX"
    },
    {
      "id": 97,
      "module": "Demographic",
      "field": "PatientCommunicationPreference",
      "type": "text",
      "description": "Patient Communication Preference"
    },
    {
      "id": 98,
      "module": "Demographic",
      "field": "EmergencyContact_Person",
      "type": "text",
      "description": "Emergency Contact Person"
    },
    {
      "id": 99,
      "module": "Demographic",
      "field": "EmergencyContact_Phone",
      "type": "text",
      "description": "Emergency Contact Phone"
    },
    {
      "id": 100,
      "module": "Demographic",
      "field": "EmergencyContact_Mobile",
      "type": "text",
      "description": "Emergency Contact Mobile"
    },
    {
      "id": 101,
      "module": "Demographic",
      "field": "EmergencyContact_Person_RelationshipCode",
      "type": "text",
      "description": "Emergency Contact Person Relationship Code"
    },
    {
      "id": 102,
      "module": "Demographic",
      "field": "EmergencyContact_Person_EmergencyRelationshipDesc",
      "type": "text",
      "description": "Emergency Contact Person Emergency Relationship Description"
    },
    {
      "id": 103,
      "module": "Demographic",
      "field": "PatientOccupation",
      "type": "text",
      "description": "Patient Occupation"
    },
    {
      "id": 104,
      "module": "Demographic",
      "field": "EmploymentStatus",
      "type": "text",
      "description": "Employment Status"
    },
    {
      "id": 105,
      "module": "Demographic",
      "field": "PlaceofEmployment",
      "type": "text",
      "description": "Place of Employment"
    },
    {
      "id": 106,
      "module": "Demographic",
      "field": "WorkAddressLine1",
      "type": "text",
      "description": "Work Address Line 1"
    },
    {
      "id": 107,
      "module": "Demographic",
      "field": "WorkAddressLine2",
      "type": "text",
      "description": "Work Address Line 2"
    },
    {
      "id": 108,
      "module": "Demographic",
      "field": "WorkCity",
      "type": "text",
      "description": "Work City"
    },
    {
      "id": 109,
      "module": "Demographic",
      "field": "WorkState",
      "type": "text",
      "description": "Work State"
    },
    {
      "id": 110,
      "module": "Demographic",
      "field": "WorkZIP",
      "type": "text",
      "description": "Work ZIP"
    },
    {
      "id": 111,
      "module": "Demographic",
      "field": "WorkPhone",
      "type": "text",
      "description": "Work Phone"
    },
    {
      "id": 112,
      "module": "Demographic",
      "field": "WorkFAX",
      "type": "text",
      "description": "Work FAX"
    },
    {
      "id": 113,
      "module": "Demographic",
      "field": "WorkCounty",
      "type": "text",
      "description": "Work County"
    },
    {
      "id": 114,
      "module": "Demographic",
      "field": "WorkMobile",
      "type": "text",
      "description": "Work Mobile"
    },
    {
      "id": 115,
      "module": "Demographic",
      "field": "WorkCountry",
      "type": "text",
      "description": "Work Country"
    },
    {
      "id": 116,
      "module": "Demographic",
      "field": "WorkEmail",
      "type": "text",
      "description": "Work Email "
    },
    {
      "id": 117,
      "module": "Demographic",
      "field": "EmployerName",
      "type": "text",
      "description": "Employer Name"
    },
    {
      "id": 118,
      "module": "Demographic",
      "field": "RetirementDate",
      "type": "date",
      "description": "Retirement Date"
    },
    {
      "id": 119,
      "module": "Demographic",
      "field": "EmploymentType",
      "type": "text",
      "description": "Employment Type"
    },
    {
      "id": 120,
      "module": "Demographic",
      "field": "MotherFirstName",
      "type": "text",
      "description": "Mother First Name"
    },
    {
      "id": 121,
      "module": "Demographic",
      "field": "MotherMiddleName",
      "type": "text",
      "description": "Mother Middle Name"
    },
    {
      "id": 122,
      "module": "Demographic",
      "field": "MotherLastName",
      "type": "text",
      "description": "Mother Last Name"
    },
    {
      "id": 123,
      "module": "Demographic",
      "field": "Mother_AddressLine1",
      "type": "text",
      "description": "Mother Address Line 1"
    },
    {
      "id": 124,
      "module": "Demographic",
      "field": "Mother_AddressLine2",
      "type": "text",
      "description": "Mother Address Line 2"
    },
    {
      "id": 125,
      "module": "Demographic",
      "field": "Mother_City",
      "type": "text",
      "description": "Mother City"
    },
    {
      "id": 126,
      "module": "Demographic",
      "field": "Mother_State",
      "type": "text",
      "description": "Mother State"
    },
    {
      "id": 127,
      "module": "Demographic",
      "field": "Mother_ZIP",
      "type": "text",
      "description": "Mother ZIP"
    },
    {
      "id": 128,
      "module": "Demographic",
      "field": "Mother_County",
      "type": "text",
      "description": "Mother County"
    },
    {
      "id": 129,
      "module": "Demographic",
      "field": "Mother_Country",
      "type": "text",
      "description": "Mother Country"
    },
    {
      "id": 130,
      "module": "Demographic",
      "field": "Mother_Phone",
      "type": "text",
      "description": "Mother Phone"
    },
    {
      "id": 131,
      "module": "Demographic",
      "field": "Mother_Mobile",
      "type": "text",
      "description": "Mother Mobile"
    },
    {
      "id": 132,
      "module": "Demographic",
      "field": "Mother_FAX",
      "type": "text",
      "description": "Mother FAX"
    },
    {
      "id": 133,
      "module": "Demographic",
      "field": "Mother_Email",
      "type": "text",
      "description": "Mother Email"
    },
    {
      "id": 134,
      "module": "Demographic",
      "field": "FatherFirstName",
      "type": "text",
      "description": "Father First Name"
    },
    {
      "id": 135,
      "module": "Demographic",
      "field": "FatherMiddleName",
      "type": "text",
      "description": "Father Middle Name"
    },
    {
      "id": 136,
      "module": "Demographic",
      "field": "FatherLastName",
      "type": "text",
      "description": "Father Last Name"
    },
    {
      "id": 137,
      "module": "Demographic",
      "field": "Father_AddressLine1",
      "type": "text",
      "description": "Father Address Line 1"
    },
    {
      "id": 138,
      "module": "Demographic",
      "field": "Father_AddressLine2",
      "type": "text",
      "description": "Father Address Line 2"
    },
    {
      "id": 139,
      "module": "Demographic",
      "field": "Father_City",
      "type": "text",
      "description": "Father City"
    },
    {
      "id": 140,
      "module": "Demographic",
      "field": "Father_State",
      "type": "text",
      "description": "Father State"
    },
    {
      "id": 141,
      "module": "Demographic",
      "field": "Father_ZIP",
      "type": "text",
      "description": "Father ZIP"
    },
    {
      "id": 142,
      "module": "Demographic",
      "field": "Father_County",
      "type": "text",
      "description": "Father County"
    },
    {
      "id": 143,
      "module": "Demographic",
      "field": "Father_Country",
      "type": "text",
      "description": "Father Country"
    },
    {
      "id": 144,
      "module": "Demographic",
      "field": "Father_Phone",
      "type": "text",
      "description": "Father Phone"
    },
    {
      "id": 145,
      "module": "Demographic",
      "field": "Father_Mobile",
      "type": "text",
      "description": "Father Mobile"
    },
    {
      "id": 146,
      "module": "Demographic",
      "field": "Father_FAX",
      "type": "text",
      "description": "Father FAX"
    },
    {
      "id": 147,
      "module": "Demographic",
      "field": "Father_Email",
      "type": "text",
      "description": "Father Email"
    },
    {
      "id": 148,
      "module": "Demographic",
      "field": "GuardianFirstName",
      "type": "text",
      "description": "Guardian First Name"
    },
    {
      "id": 149,
      "module": "Demographic",
      "field": "GuardianMiddleName",
      "type": "text",
      "description": "Guardian Middle Name"
    },
    {
      "id": 150,
      "module": "Demographic",
      "field": "GuardianLastName",
      "type": "text",
      "description": "Guardian Last Name"
    },
    {
      "id": 151,
      "module": "Demographic",
      "field": "GuardianRelationshipCode",
      "type": "text",
      "description": "Guardian Relationship Code"
    },
    {
      "id": 152,
      "module": "Demographic",
      "field": "GuardianRelationshipDescription",
      "type": "text",
      "description": "Guardian Relationship Description"
    },
    {
      "id": 153,
      "module": "Demographic",
      "field": "Guardian_AddressLine1",
      "type": "text",
      "description": "Guardian Address Line 1"
    },
    {
      "id": 154,
      "module": "Demographic",
      "field": "Guardian_AddressLine2",
      "type": "text",
      "description": "Guardian Address Line 2"
    },
    {
      "id": 155,
      "module": "Demographic",
      "field": "Guardian_City",
      "type": "text",
      "description": "Guardian City"
    },
    {
      "id": 156,
      "module": "Demographic",
      "field": "Guardian_State",
      "type": "text",
      "description": "Guardian State"
    },
    {
      "id": 157,
      "module": "Demographic",
      "field": "Guardian_ZIP",
      "type": "text",
      "description": "Guardian ZIP"
    },
    {
      "id": 158,
      "module": "Demographic",
      "field": "Guardian_County",
      "type": "text",
      "description": "Guardian County"
    },
    {
      "id": 159,
      "module": "Demographic",
      "field": "Guardian_Country",
      "type": "text",
      "description": "Guardian Country"
    },
    {
      "id": 160,
      "module": "Demographic",
      "field": "Guardian_Phone",
      "type": "text",
      "description": "Guardian Phone"
    },
    {
      "id": 161,
      "module": "Demographic",
      "field": "Guardian_Mobile",
      "type": "text",
      "description": "Guardian Mobile"
    },
    {
      "id": 162,
      "module": "Demographic",
      "field": "Guardian_FAX",
      "type": "text",
      "description": "Guardian FAX"
    },
    {
      "id": 163,
      "module": "Demographic",
      "field": "Guardian_Email",
      "type": "text",
      "description": "Guardian Email"
    },
    {
      "id": 164,
      "module": "Demographic",
      "field": "PatientRegistrationDate",
      "type": "date",
      "description": "Patient Registration Date"
    },
    {
      "id": 165,
      "module": "Demographic",
      "field": "PatientLawyer",
      "type": "text",
      "description": "Patient Lawyer"
    },
    {
      "id": 166,
      "module": "Demographic",
      "field": "PatientSpouseName",
      "type": "text",
      "description": "Patient Spouse Name"
    },
    {
      "id": 167,
      "module": "Demographic",
      "field": "PatientSpousePhone",
      "type": "text",
      "description": "Patient Spouse Phone"
    },
    {
      "id": 168,
      "module": "Demographic",
      "field": "PatientSignatureOnFile",
      "type": "boolean",
      "description": "Patient Signature On File  "
    },
    {
      "id": 169,
      "module": "Demographic",
      "field": "DefaultFacilityLocation",
      "type": "text",
      "description": "Default Facility Location"
    },
    {
      "id": 170,
      "module": "Demographic",
      "field": "PatientProviderID",
      "type": "numeric",
      "description": "Patient Provider Identifier"
    },
    {
      "id": 171,
      "module": "Demographic",
      "field": "PatientProviderFirstName",
      "type": "text",
      "description": "Patient Provider First Name"
    },
    {
      "id": 172,
      "module": "Demographic",
      "field": "PatientProviderMiddleName",
      "type": "text",
      "description": "Patient Provider Middle Name"
    },
    {
      "id": 173,
      "module": "Demographic",
      "field": "PatientProviderLastName",
      "type": "text",
      "description": "Patient Provider Last Name"
    },
    {
      "id": 174,
      "module": "Demographic",
      "field": "MedicalCategory",
      "type": "text",
      "description": "Medical Category"
    },
    {
      "id": 175,
      "module": "Denials",
      "field": "CloseDate",
      "type": "date",
      "description": "Close Date"
    },
    {
      "id": 176,
      "module": "Denials",
      "field": "Tray",
      "type": "text",
      "description": "Tray"
    },
    {
      "id": 177,
      "module": "Denials",
      "field": "Pat.Code",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 178,
      "module": "Denials",
      "field": "Claim#",
      "type": "text",
      "description": "Claim Number"
    },
    {
      "id": 179,
      "module": "Denials",
      "field": "DOS",
      "type": "date",
      "description": "Date of Service"
    },
    {
      "id": 180,
      "module": "Denials",
      "field": "Proc",
      "type": "text",
      "description": "CPT Code"
    },
    {
      "id": 181,
      "module": "Denials",
      "field": "Unit",
      "type": "numeric",
      "description": "Unit"
    },
    {
      "id": 182,
      "module": "Denials",
      "field": "Charges",
      "type": "numeric",
      "description": "Charges"
    },
    {
      "id": 183,
      "module": "Denials",
      "field": "InsPayment",
      "type": "numeric",
      "description": "Insurance Payment"
    },
    {
      "id": 184,
      "module": "Denials",
      "field": "Balance",
      "type": "numeric",
      "description": "Balance"
    },
    {
      "id": 185,
      "module": "Denials",
      "field": "ReceiptPayer",
      "type": "text",
      "description": "Receipt Payer"
    },
    {
      "id": 186,
      "module": "Denials",
      "field": "Adj",
      "type": "numeric",
      "description": "Adjustment"
    },
    {
      "id": 187,
      "module": "Denials",
      "field": "RemarkList",
      "type": "text",
      "description": "Remark List"
    },
    {
      "id": 188,
      "module": "Eligibility",
      "field": "ProviderName",
      "type": "text",
      "description": "Provider Name"
    },
    {
      "id": 189,
      "module": "Eligibility",
      "field": "sLocation",
      "type": "text",
      "description": "Location"
    },
    {
      "id": 190,
      "module": "Eligibility",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 191,
      "module": "Eligibility",
      "field": "PatientName",
      "type": "text",
      "description": "Patient Name"
    },
    {
      "id": 192,
      "module": "Eligibility",
      "field": "PayerName",
      "type": "text",
      "description": "Payer Name"
    },
    {
      "id": 193,
      "module": "Eligibility",
      "field": "SubscriberID",
      "type": "text",
      "description": "Subscriber Identifier"
    },
    {
      "id": 194,
      "module": "Eligibility",
      "field": "SubscriberName",
      "type": "text",
      "description": "Subscriber Name"
    },
    {
      "id": 195,
      "module": "Eligibility",
      "field": "ServiceType",
      "type": "text",
      "description": "Service Type"
    },
    {
      "id": 196,
      "module": "Eligibility",
      "field": "CoverageType",
      "type": "text",
      "description": "Coverage Type"
    },
    {
      "id": 197,
      "module": "Eligibility",
      "field": "InNet",
      "type": "text",
      "description": "In Net"
    },
    {
      "id": 198,
      "module": "Eligibility",
      "field": "AuthReqBenefit",
      "type": "text",
      "description": "Auth Request Benefit"
    },
    {
      "id": 199,
      "module": "Eligibility",
      "field": "sBenefit",
      "type": "text",
      "description": "Benefit"
    },
    {
      "id": 200,
      "module": "Eligibility",
      "field": "BenefitAmount",
      "type": "text",
      "description": "Benefit Amount"
    },
    {
      "id": 201,
      "module": "Eligibility",
      "field": "Dates",
      "type": "date",
      "description": "Dates"
    },
    {
      "id": 202,
      "module": "Eligibility",
      "field": "Message",
      "type": "text",
      "description": "Message"
    },
    {
      "id": 203,
      "module": "Guarantor",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 204,
      "module": "Guarantor",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 205,
      "module": "Guarantor",
      "field": "FirstName_CompanyName",
      "type": "text",
      "description": "First Name Company Name"
    },
    {
      "id": 206,
      "module": "Guarantor",
      "field": "MiddleName",
      "type": "text",
      "description": "Middle Name Company Name"
    },
    {
      "id": 207,
      "module": "Guarantor",
      "field": "LastName",
      "type": "text",
      "description": "Last Name Company Name"
    },
    {
      "id": 208,
      "module": "Guarantor",
      "field": "Relation",
      "type": "text",
      "description": "Relation"
    },
    {
      "id": 209,
      "module": "Guarantor",
      "field": "DOB",
      "type": "numeric",
      "description": "Date Of Birth"
    },
    {
      "id": 210,
      "module": "Guarantor",
      "field": "Gender",
      "type": "text",
      "description": "Gender"
    },
    {
      "id": 211,
      "module": "Guarantor",
      "field": "sSSN",
      "type": "text",
      "description": "SSN"
    },
    {
      "id": 212,
      "module": "Guarantor",
      "field": "GuarantorFlag",
      "type": "numeric",
      "description": "Guarantor Flag"
    },
    {
      "id": 213,
      "module": "Guarantor",
      "field": "AddressLine1",
      "type": "text",
      "description": "Address Line 1"
    },
    {
      "id": 214,
      "module": "Guarantor",
      "field": "AddressLine2",
      "type": "text",
      "description": "Address Line 2"
    },
    {
      "id": 215,
      "module": "Guarantor",
      "field": "City",
      "type": "text",
      "description": "City"
    },
    {
      "id": 216,
      "module": "Guarantor",
      "field": "State",
      "type": "text",
      "description": "State"
    },
    {
      "id": 217,
      "module": "Guarantor",
      "field": "ZIP",
      "type": "text",
      "description": "ZIP"
    },
    {
      "id": 218,
      "module": "Guarantor",
      "field": "County",
      "type": "text",
      "description": "County"
    },
    {
      "id": 219,
      "module": "Guarantor",
      "field": "Country",
      "type": "text",
      "description": "Country"
    },
    {
      "id": 220,
      "module": "Guarantor",
      "field": "Phone",
      "type": "text",
      "description": "Phone"
    },
    {
      "id": 221,
      "module": "Guarantor",
      "field": "Mobile",
      "type": "text",
      "description": "Mobile"
    },
    {
      "id": 222,
      "module": "Guarantor",
      "field": "Fax",
      "type": "text",
      "description": "Fax"
    },
    {
      "id": 223,
      "module": "Guarantor",
      "field": "Email",
      "type": "text",
      "description": "Email"
    },
    {
      "id": 224,
      "module": "Guarantor",
      "field": "IsActive",
      "type": "boolean",
      "description": "Is Active"
    },
    {
      "id": 225,
      "module": "History",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 226,
      "module": "History",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 227,
      "module": "History",
      "field": "VisitID",
      "type": "numeric",
      "description": "Visit Identifier"
    },
    {
      "id": 228,
      "module": "History",
      "field": "VisitDate",
      "type": "date",
      "description": "Visit Date"
    },
    {
      "id": 229,
      "module": "History",
      "field": "HistoryCategory",
      "type": "text",
      "description": "History Category"
    },
    {
      "id": 230,
      "module": "History",
      "field": "HistoryItem",
      "type": "text",
      "description": "History Item"
    },
    {
      "id": 231,
      "module": "History",
      "field": "Comments",
      "type": "text",
      "description": "Comments"
    },
    {
      "id": 232,
      "module": "History",
      "field": "Reaction",
      "type": "text",
      "description": "Reaction for Category type"
    },
    {
      "id": 233,
      "module": "History",
      "field": "Family Member",
      "type": "text",
      "description": "Family Member"
    },
    {
      "id": 234,
      "module": "History",
      "field": "Smoking Status",
      "type": "text",
      "description": "Smoking Status"
    },
    {
      "id": 235,
      "module": "History",
      "field": "Functional Status",
      "type": "text",
      "description": "Functional Status"
    },
    {
      "id": 236,
      "module": "History",
      "field": "HistoryStatus",
      "type": "text",
      "description": "History Status"
    },
    {
      "id": 237,
      "module": "History",
      "field": "DrugID",
      "type": "numeric",
      "description": "Drug Identifier"
    },
    {
      "id": 238,
      "module": "History",
      "field": "MedicalConditionId",
      "type": "numeric",
      "description": "Medical Condition Identifier"
    },
    {
      "id": 239,
      "module": "History",
      "field": "DrugName",
      "type": "text",
      "description": "Drug Name"
    },
    {
      "id": 240,
      "module": "History",
      "field": "Dosage",
      "type": "text",
      "description": "Dosage"
    },
    {
      "id": 241,
      "module": "History",
      "field": "NDCCode",
      "type": "text",
      "description": "NDC Code"
    },
    {
      "id": 242,
      "module": "History",
      "field": "DrugDatabaseID",
      "type": "numeric",
      "description": "Drug Database Identifier"
    },
    {
      "id": 243,
      "module": "History",
      "field": "DOEAllergy",
      "type": "date",
      "description": "Date Of Estimated Allergy"
    },
    {
      "id": 244,
      "module": "History",
      "field": "ConceptID",
      "type": "text",
      "description": "Concept Identifier"
    },
    {
      "id": 245,
      "module": "History",
      "field": "DescriptionID",
      "type": "text",
      "description": "Description Identifier"
    },
    {
      "id": 246,
      "module": "History",
      "field": "SnoMedID",
      "type": "text",
      "description": "Description Identifier"
    },
    {
      "id": 247,
      "module": "History",
      "field": "Description",
      "type": "text",
      "description": "Description"
    },
    {
      "id": 248,
      "module": "History",
      "field": "sTranID1",
      "type": "text",
      "description": "Transition Identifier 1"
    },
    {
      "id": 249,
      "module": "History",
      "field": "sTranID2",
      "type": "text",
      "description": "Transition Identifier 2"
    },
    {
      "id": 250,
      "module": "History",
      "field": "sTranID3",
      "type": "text",
      "description": "Transition Identifier 3"
    },
    {
      "id": 251,
      "module": "History",
      "field": "ICD9",
      "type": "text",
      "description": "ICD9 "
    },
    {
      "id": 252,
      "module": "Immunization",
      "field": "Patient Code",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 253,
      "module": "Immunization",
      "field": "LastName",
      "type": "text",
      "description": "Last Name"
    },
    {
      "id": 254,
      "module": "Immunization",
      "field": "FirstName",
      "type": "text",
      "description": "FIrst Name"
    },
    {
      "id": 255,
      "module": "Immunization",
      "field": "Date",
      "type": "date",
      "description": "Date"
    },
    {
      "id": 256,
      "module": "Immunization",
      "field": "Entered By",
      "type": "text",
      "description": "Login User"
    },
    {
      "id": 257,
      "module": "Immunization",
      "field": "Location",
      "type": "text",
      "description": "Location"
    },
    {
      "id": 258,
      "module": "Immunization",
      "field": "IMType",
      "type": "text",
      "description": "Immunization Type"
    },
    {
      "id": 259,
      "module": "Immunization",
      "field": "Administering Provider",
      "type": "text",
      "description": "Administering Provider"
    },
    {
      "id": 260,
      "module": "Immunization",
      "field": "Ordering Provider",
      "type": "text",
      "description": "Ordering Provider"
    },
    {
      "id": 261,
      "module": "Immunization",
      "field": "SKU",
      "type": "text",
      "description": "Stock Keeping Unit"
    },
    {
      "id": 262,
      "module": "Immunization",
      "field": "Trade Name",
      "type": "text",
      "description": "Trade Name"
    },
    {
      "id": 263,
      "module": "Immunization",
      "field": "Vaccine",
      "type": "text",
      "description": "Vaccine Name"
    },
    {
      "id": 264,
      "module": "Immunization",
      "field": "Manufacturer",
      "type": "text",
      "description": "Manufacturer"
    },
    {
      "id": 265,
      "module": "Immunization",
      "field": "Exp Date",
      "type": "date",
      "description": "Expiry Date"
    },
    {
      "id": 266,
      "module": "Immunization",
      "field": "LotNumber",
      "type": "text",
      "description": "Lot Number"
    },
    {
      "id": 267,
      "module": "Immunization",
      "field": "Category",
      "type": "text",
      "description": "Category"
    },
    {
      "id": 268,
      "module": "Immunization",
      "field": "Doses Given",
      "type": "text",
      "description": "Doses Given"
    },
    {
      "id": 269,
      "module": "Immunization",
      "field": "Route",
      "type": "text",
      "description": "Route Given"
    },
    {
      "id": 270,
      "module": "Immunization",
      "field": "Amount Given",
      "type": "text",
      "description": "Amount Given"
    },
    {
      "id": 271,
      "module": "Immunization",
      "field": "Unit of measure",
      "type": "text",
      "description": "Unit of Measure"
    },
    {
      "id": 272,
      "module": "Immunization",
      "field": "Site",
      "type": "text",
      "description": "Site Given"
    },
    {
      "id": 273,
      "module": "Immunization",
      "field": "Observation Date",
      "type": "date",
      "description": "Observation Date"
    },
    {
      "id": 274,
      "module": "Immunization",
      "field": "Refusal Reason",
      "type": "text",
      "description": "Refusal Reason"
    },
    {
      "id": 275,
      "module": "Immunization",
      "field": "Refused By",
      "type": "text",
      "description": "Refused By"
    },
    {
      "id": 276,
      "module": "Immunization",
      "field": "CQM Categories",
      "type": "text",
      "description": "CQM Categories"
    },
    {
      "id": 277,
      "module": "Immunization",
      "field": "Refusal Comments",
      "type": "text",
      "description": "Refusal Comments"
    },
    {
      "id": 278,
      "module": "Immunization",
      "field": "Diagnosis",
      "type": "text",
      "description": "Diagnosis"
    },
    {
      "id": 279,
      "module": "Immunization",
      "field": "NDC",
      "type": "text",
      "description": "NDC"
    },
    {
      "id": 280,
      "module": "Immunization",
      "field": "Comments",
      "type": "text",
      "description": "Comments"
    },
    {
      "id": 281,
      "module": "Immunization",
      "field": "Funding Source",
      "type": "text",
      "description": "Funding Source"
    },
    {
      "id": 282,
      "module": "Immunization",
      "field": "Status",
      "type": "text",
      "description": "Status"
    },
    {
      "id": 283,
      "module": "Immunization",
      "field": "Due Date",
      "type": "date",
      "description": "Due Date"
    },
    {
      "id": 284,
      "module": "Immunization",
      "field": "CPT",
      "type": "text",
      "description": " CPT Code"
    },
    {
      "id": 285,
      "module": "Immunization",
      "field": "FundingType",
      "type": "text",
      "description": "Funding Type"
    },
    {
      "id": 286,
      "module": "Immunization",
      "field": "SNOMED Code",
      "type": "text",
      "description": "SNOMED Code"
    },
    {
      "id": 287,
      "module": "Immunization",
      "field": "SNOMED Description",
      "type": "text",
      "description": "SNOMED Description"
    },
    {
      "id": 288,
      "module": "Immunization",
      "field": "Publicity Code",
      "type": "text",
      "description": "Publicity Code"
    },
    {
      "id": 289,
      "module": "Immunization",
      "field": "Publicity Effective Date",
      "type": "date",
      "description": "Publicity Effective Date"
    },
    {
      "id": 290,
      "module": "Immunization",
      "field": "Patient Had a Reaction",
      "type": "boolean",
      "description": "Did Patient Have a Reaction"
    },
    {
      "id": 291,
      "module": "Immunization",
      "field": "Onset Date",
      "type": "date",
      "description": "Onset Date"
    },
    {
      "id": 292,
      "module": "Immunization",
      "field": "Describe Adverse Event",
      "type": "text",
      "description": "Describe Adverse Event"
    },
    {
      "id": 293,
      "module": "Immunization",
      "field": "Patient Died",
      "type": "boolean",
      "description": "Is Patient Dead"
    },
    {
      "id": 294,
      "module": "Immunization",
      "field": "Patient Died Date",
      "type": "date",
      "description": "Patient Died Date"
    },
    {
      "id": 295,
      "module": "Immunization",
      "field": "Life Threatening Illness",
      "type": "boolean",
      "description": "Life Threatening Illness"
    },
    {
      "id": 296,
      "module": "Immunization",
      "field": "Required Emergency Room",
      "type": "boolean",
      "description": "Required Emergency Room"
    },
    {
      "id": 297,
      "module": "Immunization",
      "field": "Required Hospitalization",
      "type": "boolean",
      "description": "Required Hospitalization"
    },
    {
      "id": 298,
      "module": "Immunization",
      "field": "Hospitalization Days",
      "type": "numeric",
      "description": "Hospitalization Days"
    },
    {
      "id": 299,
      "module": "Immunization",
      "field": " Resulted In Prolongation of hospitalization",
      "type": "boolean",
      "description": "Resulted In Prolongation of hospitalization"
    },
    {
      "id": 300,
      "module": "Immunization",
      "field": "Resulted In Permanent Disability",
      "type": "boolean",
      "description": "Resulted In Permanent Disability"
    },
    {
      "id": 301,
      "module": "Immunization",
      "field": "Patient Recovered",
      "type": "text",
      "description": "Patient Recovered"
    },
    {
      "id": 302,
      "module": "Insurances",
      "field": "nPatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 303,
      "module": "Insurances",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 304,
      "module": "Insurances",
      "field": "InsuranceCompanyId",
      "type": "numeric",
      "description": "Insurance Company Identifier"
    },
    {
      "id": 305,
      "module": "Insurances",
      "field": "PatientInsuranceInternalId",
      "type": "numeric",
      "description": "Patient Insurance Internal Identifier"
    },
    {
      "id": 306,
      "module": "Insurances",
      "field": "InsuranceName",
      "type": "text",
      "description": "Insurance Name"
    },
    {
      "id": 307,
      "module": "Insurances",
      "field": "InsuranceStatus",
      "type": "numeric",
      "description": "Insurance Status"
    },
    {
      "id": 308,
      "module": "Insurances",
      "field": "InsuranceID_Policy#",
      "type": "text",
      "description": "Insurance Identifier Policy Number"
    },
    {
      "id": 309,
      "module": "Insurances",
      "field": "Group",
      "type": "text",
      "description": "Group"
    },
    {
      "id": 310,
      "module": "Insurances",
      "field": "sPayerID",
      "type": "text",
      "description": "Payer Identifier"
    },
    {
      "id": 311,
      "module": "Insurances",
      "field": "InsuranceType",
      "type": "text",
      "description": "Insurance Type"
    },
    {
      "id": 312,
      "module": "Insurances",
      "field": "InsuranceTypeDescription",
      "type": "text",
      "description": "Insurance Type Description"
    },
    {
      "id": 313,
      "module": "Insurances",
      "field": "InsuranceTypeCodeDefault",
      "type": "text",
      "description": "Default Insurance Type Code "
    },
    {
      "id": 314,
      "module": "Insurances",
      "field": "InsuranceTypeDescriptionDefault",
      "type": "text",
      "description": "Default Insurance Type Description "
    },
    {
      "id": 315,
      "module": "Insurances",
      "field": "InsuranceTypeCodeMedicare",
      "type": "text",
      "description": "Insurance Type Code Medicare"
    },
    {
      "id": 316,
      "module": "Insurances",
      "field": "InsuranceTypeMedicareDescription",
      "type": "text",
      "description": "Insurance Type Medicare Description "
    },
    {
      "id": 317,
      "module": "Insurances",
      "field": "DeductibleAmount",
      "type": "numeric",
      "description": "Deductible Amount"
    },
    {
      "id": 318,
      "module": "Insurances",
      "field": "CoveragePercent",
      "type": "numeric",
      "description": "Coverage Percent"
    },
    {
      "id": 319,
      "module": "Insurances",
      "field": "CopayAmount",
      "type": "numeric",
      "description": "Copay Amount"
    },
    {
      "id": 320,
      "module": "Insurances",
      "field": "StartDate",
      "type": "date",
      "description": "Start Date"
    },
    {
      "id": 321,
      "module": "Insurances",
      "field": "EndDate",
      "type": "date",
      "description": "End Date"
    },
    {
      "id": 322,
      "module": "Insurances",
      "field": "SubscriberSameAsPatient",
      "type": "boolean",
      "description": "Subscriber Same As Patient"
    },
    {
      "id": 323,
      "module": "Insurances",
      "field": "PatientRelationShip_To_Subscriber_Code",
      "type": "numeric",
      "description": "Patient Relationship To Subscriber Code"
    },
    {
      "id": 324,
      "module": "Insurances",
      "field": "PatientRelationShip_To_Subscriber",
      "type": "text",
      "description": "Patient Relationship To Subscriber"
    },
    {
      "id": 325,
      "module": "Insurances",
      "field": "SubscriberLastName",
      "type": "text",
      "description": "Subscriber Last Name"
    },
    {
      "id": 326,
      "module": "Insurances",
      "field": "SubscriberFirstName",
      "type": "text",
      "description": "Subscriber First Name"
    },
    {
      "id": 327,
      "module": "Insurances",
      "field": "SubscriberMiddleName",
      "type": "text",
      "description": "Subscriber Middle Name"
    },
    {
      "id": 328,
      "module": "Insurances",
      "field": "SubscriberDateOfBirth",
      "type": "date",
      "description": "Subscriber Date of Birth"
    },
    {
      "id": 329,
      "module": "Insurances",
      "field": "SubscriberGender",
      "type": "text",
      "description": "Subscriber Gender"
    },
    {
      "id": 330,
      "module": "Insurances",
      "field": "SubscriberPhone",
      "type": "text",
      "description": "Subscriber Phone"
    },
    {
      "id": 331,
      "module": "Insurances",
      "field": "SubscriberAddressLine1",
      "type": "text",
      "description": "Subscriber Address Line 1"
    },
    {
      "id": 332,
      "module": "Insurances",
      "field": "SubscriberAddressLine2",
      "type": "text",
      "description": "Subscriber Address Line 2"
    },
    {
      "id": 333,
      "module": "Insurances",
      "field": "SubscriberCity",
      "type": "text",
      "description": "Subscriber City"
    },
    {
      "id": 334,
      "module": "Insurances",
      "field": "SubscriberState",
      "type": "text",
      "description": "Subscriber State"
    },
    {
      "id": 335,
      "module": "Insurances",
      "field": "SubscriberZip",
      "type": "text",
      "description": "Subscriber Zip"
    },
    {
      "id": 336,
      "module": "Insurances",
      "field": "SubscriberCounty",
      "type": "text",
      "description": "Subscriber County"
    },
    {
      "id": 337,
      "module": "Insurances",
      "field": "SubscriberCountry",
      "type": "text",
      "description": "Subscriber Country"
    },
    {
      "id": 338,
      "module": "Insurances",
      "field": "IsWorkersComp",
      "type": "boolean",
      "description": "Is Workers Comp"
    },
    {
      "id": 339,
      "module": "Insurances",
      "field": "IsAutoClaim",
      "type": "boolean",
      "description": "Is Auto Claim"
    },
    {
      "id": 340,
      "module": "Insurances",
      "field": "IsAssignmentofBenefit",
      "type": "boolean",
      "description": "Assignment of Benefit"
    },
    {
      "id": 341,
      "module": "Medical devices or equipment",
      "field": "ImplantDate",
      "type": "date",
      "description": "Implant Date"
    },
    {
      "id": 342,
      "module": "Medical devices or equipment",
      "field": "IssuingAgency",
      "type": "text",
      "description": "Issuing Agency"
    },
    {
      "id": 343,
      "module": "Medical devices or equipment",
      "field": "BrandName",
      "type": "text",
      "description": "Brand Name"
    },
    {
      "id": 344,
      "module": "Medical devices or equipment",
      "field": "CompanyName",
      "type": "text",
      "description": "Company Name"
    },
    {
      "id": 345,
      "module": "Medical devices or equipment",
      "field": "VersionModel",
      "type": "text",
      "description": "Version Model"
    },
    {
      "id": 346,
      "module": "Medical devices or equipment",
      "field": "MRISafetyStatus",
      "type": "text",
      "description": "MRI Safety Status"
    },
    {
      "id": 347,
      "module": "Medical devices or equipment",
      "field": "LabeledContainsNRL",
      "type": "boolean",
      "description": "Labeled Contains NRL"
    },
    {
      "id": 348,
      "module": "Medical devices or equipment",
      "field": "SerialNumber",
      "type": "text",
      "description": "Serial Number"
    },
    {
      "id": 349,
      "module": "Medical devices or equipment",
      "field": "LotBatchNumber",
      "type": "text",
      "description": "Lot Batch Number"
    },
    {
      "id": 350,
      "module": "Medical devices or equipment",
      "field": "ManufacturingDate",
      "type": "text",
      "description": "Manufacturing Date"
    },
    {
      "id": 351,
      "module": "Medical devices or equipment",
      "field": "ExpirationDate",
      "type": "text",
      "description": "Expiration Date"
    },
    {
      "id": 352,
      "module": "Medical devices or equipment",
      "field": "DeviceHCTP",
      "type": "text",
      "description": "Device HCTP"
    },
    {
      "id": 353,
      "module": "Medical devices or equipment",
      "field": "DeviceStatus",
      "type": "boolean",
      "description": "Device Status"
    },
    {
      "id": 354,
      "module": "Medical devices or equipment",
      "field": "InactiveDate",
      "type": "date",
      "description": "Inactive Date"
    },
    {
      "id": 355,
      "module": "Medical devices or equipment",
      "field": "UDI",
      "type": "text",
      "description": "UDI"
    },
    {
      "id": 356,
      "module": "Medical devices or equipment",
      "field": "Machine",
      "type": "text",
      "description": "Machine"
    },
    {
      "id": 357,
      "module": "Medical devices or equipment",
      "field": "IsDeleted",
      "type": "boolean",
      "description": "Is Deleted"
    },
    {
      "id": 358,
      "module": "Medical devices or equipment",
      "field": "ReasonforDelete",
      "type": "text",
      "description": "Reason for Delete"
    },
    {
      "id": 359,
      "module": "Medical devices or equipment",
      "field": "Description",
      "type": "text",
      "description": "Description"
    },
    {
      "id": 360,
      "module": "Medical devices or equipment",
      "field": "DeviceDescription",
      "type": "text",
      "description": "Device Description"
    },
    {
      "id": 361,
      "module": "Medical devices or equipment",
      "field": "GmdnPTName",
      "type": "text",
      "description": "Global Medical Device Nomenclature Preferred Term Name"
    },
    {
      "id": 362,
      "module": "Medication",
      "field": "spatientid",
      "type": "Numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 363,
      "module": "Medication",
      "field": "dtMedicationDate",
      "type": "Date",
      "description": "Medication Date"
    },
    {
      "id": 364,
      "module": "Medication",
      "field": "sMedication",
      "type": "text",
      "description": "Medication"
    },
    {
      "id": 365,
      "module": "Medication",
      "field": "sDosage",
      "type": "text",
      "description": "Dosage"
    },
    {
      "id": 366,
      "module": "Medication",
      "field": "sRoute",
      "type": "text",
      "description": "Route"
    },
    {
      "id": 367,
      "module": "Medication",
      "field": "sFrequency",
      "type": "text",
      "description": "Frequency"
    },
    {
      "id": 368,
      "module": "Medication",
      "field": "sDuration",
      "type": "text",
      "description": "Duration"
    },
    {
      "id": 369,
      "module": "Medication",
      "field": "sAmount",
      "type": "text",
      "description": "Amount"
    },
    {
      "id": 370,
      "module": "Medication",
      "field": "sStatus",
      "type": "text",
      "description": "Status"
    },
    {
      "id": 371,
      "module": "Medication",
      "field": "dtStartDate",
      "type": "date",
      "description": "Start Date"
    },
    {
      "id": 372,
      "module": "Medication",
      "field": "dtEndDate",
      "type": "date",
      "description": "End Date"
    },
    {
      "id": 373,
      "module": "Medication",
      "field": "UserName",
      "type": "text",
      "description": "User Name"
    },
    {
      "id": 374,
      "module": "Medication",
      "field": "VisitID",
      "type": "text",
      "description": "Visit Identifier"
    },
    {
      "id": 375,
      "module": "Medication",
      "field": "NDCCode",
      "type": "text",
      "description": "NDC Code"
    },
    {
      "id": 376,
      "module": "Medication",
      "field": "Refills",
      "type": "text",
      "description": "Refills"
    },
    {
      "id": 377,
      "module": "Medication",
      "field": "Rx_sChiefComplaints",
      "type": "text",
      "description": "Rx Chief Complaints"
    },
    {
      "id": 378,
      "module": "Medication",
      "field": "sDrugForm",
      "type": "text",
      "description": "Drug Form"
    },
    {
      "id": 379,
      "module": "Medication",
      "field": "sRxNormCode",
      "type": "text",
      "description": "Rx Norm Code"
    },
    {
      "id": 380,
      "module": "Medication",
      "field": "DrugRoute",
      "type": "text",
      "description": "Drug Route"
    },
    {
      "id": 381,
      "module": "Medication",
      "field": "Pharmacy",
      "type": "text",
      "description": "Pharmacy"
    },
    {
      "id": 382,
      "module": "Medication",
      "field": "Status",
      "type": "text",
      "description": "Status"
    },
    {
      "id": 383,
      "module": "Medication",
      "field": "sMedicationID",
      "type": "text",
      "description": "Medication Identifier"
    },
    {
      "id": 384,
      "module": "Medication",
      "field": "IsAdministered",
      "type": "boolean",
      "description": "Is Administered"
    },
    {
      "id": 385,
      "module": "Medication",
      "field": "mpid",
      "type": "numeric",
      "description": "mpid"
    },
    {
      "id": 386,
      "module": "Medication",
      "field": "UpdatedBy",
      "type": "text",
      "description": "Updated By"
    },
    {
      "id": 387,
      "module": "Medication",
      "field": "Prescriber",
      "type": "text",
      "description": "Prescriber"
    },
    {
      "id": 388,
      "module": "Medication",
      "field": "sMethod",
      "type": "text",
      "description": "Method"
    },
    {
      "id": 389,
      "module": "Medication",
      "field": "snpi",
      "type": "text",
      "description": "NPI"
    },
    {
      "id": 390,
      "module": "Medication",
      "field": "sreason",
      "type": "text",
      "description": "Reason"
    },
    {
      "id": 391,
      "module": "Medication",
      "field": "DrugDatabaseId",
      "type": "numeric",
      "description": "Drug Database Identifier"
    },
    {
      "id": 392,
      "module": "Medication",
      "field": "sprescriptionid",
      "type": "text",
      "description": "Prescription Identifier"
    },
    {
      "id": 393,
      "module": "Medication",
      "field": "srenewed",
      "type": "text",
      "description": "Renewed"
    },
    {
      "id": 394,
      "module": "Medication",
      "field": "IsNarcotic",
      "type": "boolean",
      "description": "Is Narcotic "
    },
    {
      "id": 395,
      "module": "Medication",
      "field": "StrengthUnit",
      "type": "text",
      "description": "Strength Unit"
    },
    {
      "id": 396,
      "module": "Medication",
      "field": "PBMSourceName",
      "type": "text",
      "description": "PBM Source Name"
    },
    {
      "id": 397,
      "module": "Medication",
      "field": "Rx_bMaySubstitute",
      "type": "boolean",
      "description": "Rx May Substitute"
    },
    {
      "id": 398,
      "module": "Medication",
      "field": "Rx_sNotes",
      "type": "text",
      "description": "Rx Notes"
    },
    {
      "id": 399,
      "module": "Medication",
      "field": "Rx_nDrugID",
      "type": "numeric",
      "description": "Rx Drug Identifier"
    },
    {
      "id": 400,
      "module": "Medication",
      "field": "Rx_blnflag",
      "type": "boolean",
      "description": "Rx lnflag"
    },
    {
      "id": 401,
      "module": "Medication",
      "field": "Rx_sLotNo",
      "type": "text",
      "description": "Rx Lot Number"
    },
    {
      "id": 402,
      "module": "Medication",
      "field": "Rx_dtExpirationdate",
      "type": "date",
      "description": "Rx Expiry Date"
    },
    {
      "id": 403,
      "module": "Medication",
      "field": "Rx_nProviderId",
      "type": "text",
      "description": "Rx Provider Identifier"
    },
    {
      "id": 404,
      "module": "Medication",
      "field": "Rx_sStatus",
      "type": "text",
      "description": "Rx Status"
    },
    {
      "id": 405,
      "module": "Medication",
      "field": "Rx_sRxReferenceNumber",
      "type": "text",
      "description": "Rx Reference Number"
    },
    {
      "id": 406,
      "module": "Medication",
      "field": "Rx_sRefillQualifier",
      "type": "text",
      "description": "Rx Refill Qualifier"
    },
    {
      "id": 407,
      "module": "Medication",
      "field": "Rx_nPharmacyId",
      "type": "text",
      "description": "Rx Pharmacy Identifier"
    },
    {
      "id": 408,
      "module": "Medication",
      "field": "Rx_nContactID",
      "type": "text",
      "description": "Rx Contact Identifier"
    },
    {
      "id": 409,
      "module": "Medication",
      "field": "Rx_sNCPDPID",
      "type": "text",
      "description": "Rx NCPDP Identifier"
    },
    {
      "id": 410,
      "module": "Medication",
      "field": "Rx_sName",
      "type": "text",
      "description": "Rx Name"
    },
    {
      "id": 411,
      "module": "Medication",
      "field": "Rx_sPrescriberNotes",
      "type": "text",
      "description": "Rx Prescriber Notes"
    },
    {
      "id": 412,
      "module": "Medication",
      "field": "Rx_eRxStatus",
      "type": "text",
      "description": "Rx Status"
    },
    {
      "id": 413,
      "module": "Medication",
      "field": "RxMed_DMSID",
      "type": "numeric",
      "description": "RxMed DMS Identifier"
    },
    {
      "id": 414,
      "module": "OB Vitals",
      "field": "VisitDate",
      "type": "date",
      "description": "Visit Date"
    },
    {
      "id": 415,
      "module": "OB Vitals",
      "field": "OBVitalDate",
      "type": "date",
      "description": "OB Vital Date"
    },
    {
      "id": 416,
      "module": "OB Vitals",
      "field": "PrePregencyWeight",
      "type": "numeric",
      "description": "Pre Pregency Weight"
    },
    {
      "id": 417,
      "module": "OB Vitals",
      "field": "WeightChange",
      "type": "numeric",
      "description": "Weight Change"
    },
    {
      "id": 418,
      "module": "OB Vitals",
      "field": "FundalHeight",
      "type": "numeric",
      "description": "Fundal Height"
    },
    {
      "id": 419,
      "module": "OB Vitals",
      "field": "TotalPregnancies",
      "type": "numeric",
      "description": "Total Pregnancies"
    },
    {
      "id": 420,
      "module": "OB Vitals",
      "field": "FullTermDeliveries",
      "type": "numeric",
      "description": "Full Term Deliveries"
    },
    {
      "id": 421,
      "module": "OB Vitals",
      "field": "Premature",
      "type": "numeric",
      "description": "Premature"
    },
    {
      "id": 422,
      "module": "OB Vitals",
      "field": "AbortionsInduced",
      "type": "numeric",
      "description": "Abortions Induced"
    },
    {
      "id": 423,
      "module": "OB Vitals",
      "field": "AbortionsSpontaneous",
      "type": "numeric",
      "description": "Abortions Spontaneous"
    },
    {
      "id": 424,
      "module": "OB Vitals",
      "field": "Ectopics",
      "type": "numeric",
      "description": "Ectopics"
    },
    {
      "id": 425,
      "module": "OB Vitals",
      "field": "LivingChildren",
      "type": "numeric",
      "description": "Living Children"
    },
    {
      "id": 426,
      "module": "OB Vitals",
      "field": "MultipleBirths",
      "type": "numeric",
      "description": "Multiple Births"
    },
    {
      "id": 427,
      "module": "OB Vitals",
      "field": "UrineGlucose",
      "type": "text",
      "description": "Urine Glucose"
    },
    {
      "id": 428,
      "module": "OB Vitals",
      "field": "UrineAlbumin",
      "type": "text",
      "description": "Urine Albumin"
    },
    {
      "id": 429,
      "module": "OB Vitals",
      "field": "PreTermLaborSigns",
      "type": "text",
      "description": "Pre Term Labor Signs"
    },
    {
      "id": 430,
      "module": "OB Vitals",
      "field": "Presentation",
      "type": "text",
      "description": "Presentation"
    },
    {
      "id": 431,
      "module": "OB Vitals",
      "field": "FetalMovement",
      "type": "text",
      "description": "Fetal Movement"
    },
    {
      "id": 432,
      "module": "OB Vitals",
      "field": "CervixExamStation",
      "type": "text",
      "description": "Cervix Exam Station"
    },
    {
      "id": 433,
      "module": "OB Vitals",
      "field": "CervixExamDilation",
      "type": "text",
      "description": "Cervix Exam Dilation"
    },
    {
      "id": 434,
      "module": "OB Vitals",
      "field": "CervixExamEffacement",
      "type": "text",
      "description": "Cervix Exam Effacement"
    },
    {
      "id": 435,
      "module": "OB Vitals",
      "field": "FetalHeartRate",
      "type": "text",
      "description": "Fetal Heart Rate"
    },
    {
      "id": 436,
      "module": "OB Vitals",
      "field": "Edema",
      "type": "text",
      "description": "Edema"
    },
    {
      "id": 437,
      "module": "OB Vitals",
      "field": "Conception",
      "type": "boolean",
      "description": "Conception"
    },
    {
      "id": 438,
      "module": "OB Vitals",
      "field": "LastMenstrualPeriod",
      "type": "boolean",
      "description": "Last Menstrual Period"
    },
    {
      "id": 439,
      "module": "OB Vitals",
      "field": "UltraSound",
      "type": "boolean",
      "description": "Ultra Sound"
    },
    {
      "id": 440,
      "module": "OB Vitals",
      "field": "ConceptionEst",
      "type": "date",
      "description": "Conception Estimated Date"
    },
    {
      "id": 441,
      "module": "OB Vitals",
      "field": "ConceptionDue",
      "type": "date",
      "description": "Conception Due Date"
    },
    {
      "id": 442,
      "module": "OB Vitals",
      "field": "ConceptionGeAge",
      "type": "text",
      "description": "Conception Gestational Age"
    },
    {
      "id": 443,
      "module": "OB Vitals",
      "field": "LMPEst",
      "type": "date",
      "description": "Last Menstrual Period Estimated Date"
    },
    {
      "id": 444,
      "module": "OB Vitals",
      "field": "LMPDue",
      "type": "date",
      "description": "Last Menstrual Period Due Date"
    },
    {
      "id": 445,
      "module": "OB Vitals",
      "field": "LMPGeAge",
      "type": "text",
      "description": "Last Menstrual Period Gestational Age"
    },
    {
      "id": 446,
      "module": "OB Vitals",
      "field": "UltraSoundEst",
      "type": "date",
      "description": "UltraSound Estimated Date"
    },
    {
      "id": 447,
      "module": "OB Vitals",
      "field": "UltraSoundDue",
      "type": "date",
      "description": "UltraSound Due Date"
    },
    {
      "id": 448,
      "module": "OB Vitals",
      "field": "UltrasoundGeAge",
      "type": "text",
      "description": "Ultrasound Gestational Age"
    },
    {
      "id": 449,
      "module": "OB Vitals",
      "field": "NextAppointment",
      "type": "text",
      "description": "Next Appointment"
    },
    {
      "id": 450,
      "module": "OB Vitals",
      "field": "GADays",
      "type": "numeric",
      "description": "Gestational Age Days"
    },
    {
      "id": 451,
      "module": "OB Vitals",
      "field": "EstimatedDueDate",
      "type": "date",
      "description": "Estimated Due Date"
    },
    {
      "id": 452,
      "module": "OB Vitals",
      "field": "IsDataMigrated",
      "type": "boolean",
      "description": "Is Data Migrated"
    },
    {
      "id": 453,
      "module": "OB Vitals",
      "field": "ISPatientAtRisk",
      "type": "boolean",
      "description": "Is Patient At Risk"
    },
    {
      "id": 454,
      "module": "OB Vitals",
      "field": "RiskComments",
      "type": "text",
      "description": "Risk Comments"
    },
    {
      "id": 455,
      "module": "OB Vitals",
      "field": "EDDComments",
      "type": "text",
      "description": "Estimated Due Date Comments"
    },
    {
      "id": 456,
      "module": "Other Care Teams",
      "field": "ContactType",
      "type": "text",
      "description": "Contact Type"
    },
    {
      "id": 457,
      "module": "Other Care Teams",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 458,
      "module": "Other Care Teams",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 459,
      "module": "Other Care Teams",
      "field": "Prefix",
      "type": "text",
      "description": "Prefix"
    },
    {
      "id": 460,
      "module": "Other Care Teams",
      "field": "FirstName",
      "type": "text",
      "description": "First Name"
    },
    {
      "id": 461,
      "module": "Other Care Teams",
      "field": "MiddleName",
      "type": "text",
      "description": "Middle Name"
    },
    {
      "id": 462,
      "module": "Other Care Teams",
      "field": "LastName",
      "type": "text",
      "description": "Last Name"
    },
    {
      "id": 463,
      "module": "Other Care Teams",
      "field": "Suffix",
      "type": "text",
      "description": "Suffix"
    },
    {
      "id": 464,
      "module": "Other Care Teams",
      "field": "Degree",
      "type": "text",
      "description": "Degree"
    },
    {
      "id": 465,
      "module": "Other Care Teams",
      "field": "Gender",
      "type": "text",
      "description": "Gender"
    },
    {
      "id": 466,
      "module": "Other Care Teams",
      "field": "AddressLine1",
      "type": "text",
      "description": "Address Line 1"
    },
    {
      "id": 467,
      "module": "Other Care Teams",
      "field": "AddressLine2",
      "type": "text",
      "description": "Address Line 2"
    },
    {
      "id": 468,
      "module": "Other Care Teams",
      "field": "City",
      "type": "text",
      "description": "City"
    },
    {
      "id": 469,
      "module": "Other Care Teams",
      "field": "State",
      "type": "text",
      "description": "State"
    },
    {
      "id": 470,
      "module": "Other Care Teams",
      "field": "ZIP",
      "type": "text",
      "description": "ZIP"
    },
    {
      "id": 471,
      "module": "Other Care Teams",
      "field": "County",
      "type": "text",
      "description": "County"
    },
    {
      "id": 472,
      "module": "Other Care Teams",
      "field": "Country",
      "type": "text",
      "description": "Country"
    },
    {
      "id": 473,
      "module": "Other Care Teams",
      "field": "Phone",
      "type": "text",
      "description": "Phone"
    },
    {
      "id": 474,
      "module": "Other Care Teams",
      "field": "Fax",
      "type": "text",
      "description": "Fax"
    },
    {
      "id": 475,
      "module": "Other Care Teams",
      "field": "Email",
      "type": "text",
      "description": "Email"
    },
    {
      "id": 476,
      "module": "Other Care Teams",
      "field": "URL",
      "type": "text",
      "description": "URL"
    },
    {
      "id": 477,
      "module": "Other Care Teams",
      "field": "Mobile",
      "type": "text",
      "description": "Mobile"
    },
    {
      "id": 478,
      "module": "Other Care Teams",
      "field": "Pager",
      "type": "text",
      "description": "Pager"
    },
    {
      "id": 479,
      "module": "Other Care Teams",
      "field": "Notes",
      "type": "text",
      "description": "Notes"
    },
    {
      "id": 480,
      "module": "Other Care Teams",
      "field": "Taxonomy",
      "type": "text",
      "description": "Taxonomy"
    },
    {
      "id": 481,
      "module": "Other Care Teams",
      "field": "TaxonomyDesc",
      "type": "text",
      "description": "Taxonomy Description"
    },
    {
      "id": 482,
      "module": "Other Care Teams",
      "field": "TaxID",
      "type": "text",
      "description": "Tax Identifier"
    },
    {
      "id": 483,
      "module": "Other Care Teams",
      "field": "UPIN",
      "type": "text",
      "description": "UPIN"
    },
    {
      "id": 484,
      "module": "Other Care Teams",
      "field": "NPI",
      "type": "text",
      "description": "NPI"
    },
    {
      "id": 485,
      "module": "Other Care Teams",
      "field": "HospitalAffiliation",
      "type": "text",
      "description": "Hospital Affiliation"
    },
    {
      "id": 486,
      "module": "Other Care Teams",
      "field": "ActiveStartTime",
      "type": "date",
      "description": "Active Start Time"
    },
    {
      "id": 487,
      "module": "Other Care Teams",
      "field": "ActiveEndTime",
      "type": "date",
      "description": "Active End Time"
    },
    {
      "id": 488,
      "module": "Other Care Teams",
      "field": "ContactStatus",
      "type": "numeric",
      "description": "Contact Status"
    },
    {
      "id": 489,
      "module": "Patient Alert",
      "field": "nAlertID",
      "type": "numeric",
      "description": "Alert Identifier"
    },
    {
      "id": 490,
      "module": "Patient Alert",
      "field": "nPatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 491,
      "module": "Patient Alert",
      "field": "sDescription",
      "type": "text",
      "description": "Description"
    },
    {
      "id": 492,
      "module": "Patient Alert",
      "field": "nStatus",
      "type": "numeric",
      "description": "Status"
    },
    {
      "id": 493,
      "module": "Patient Cases - Diagnosis",
      "field": "nId",
      "type": "numeric",
      "description": " Diagnosis Identifier"
    },
    {
      "id": 494,
      "module": "Patient Cases - Diagnosis",
      "field": "nCaseId",
      "type": "numeric",
      "description": "Case Identifier"
    },
    {
      "id": 495,
      "module": "Patient Cases - Diagnosis",
      "field": "sDxCode",
      "type": "text",
      "description": "Diagnosis Code"
    },
    {
      "id": 496,
      "module": "Patient Cases - Diagnosis",
      "field": "sDxDescription",
      "type": "text",
      "description": "Diagnosis Description"
    },
    {
      "id": 497,
      "module": "Patient Cases - Diagnosis",
      "field": "nIndex",
      "type": "numeric",
      "description": "Index"
    },
    {
      "id": 498,
      "module": "Patient Cases - Insurance Plan",
      "field": "nID",
      "type": "numeric",
      "description": "Insurance Plan Identifier"
    },
    {
      "id": 499,
      "module": "Patient Cases - Insurance Plan",
      "field": "nCaseID",
      "type": "numeric",
      "description": "Insurance Plan Case Identifier"
    },
    {
      "id": 500,
      "module": "Patient Cases - Insurance Plan",
      "field": "nInsuranceId",
      "type": "numeric",
      "description": "Insurance Identifier"
    },
    {
      "id": 501,
      "module": "Patient Cases - Insurance Plan",
      "field": "sInsuranceName",
      "type": "text",
      "description": "Insurance Name"
    },
    {
      "id": 502,
      "module": "Patient Cases - Insurance Plan",
      "field": "nResponsibilityNumber",
      "type": "numeric",
      "description": "Responsibility Number"
    },
    {
      "id": 503,
      "module": "Patient Cases - Master",
      "field": "nCaseId",
      "type": "numeric",
      "description": "Case Identifier"
    },
    {
      "id": 504,
      "module": "Patient Cases - Master",
      "field": "nPatientId",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 505,
      "module": "Patient Cases - Master",
      "field": "Case Name",
      "type": "text",
      "description": "Case Name"
    },
    {
      "id": 506,
      "module": "Patient Cases - Master",
      "field": "OB Pregnancy Case",
      "type": "boolean",
      "description": "OB Pregnancy Case"
    },
    {
      "id": 507,
      "module": "Patient Cases - Master",
      "field": "Start Date",
      "type": "date",
      "description": "Start Date"
    },
    {
      "id": 508,
      "module": "Patient Cases - Master",
      "field": "End Date",
      "type": "date",
      "description": "End Date"
    },
    {
      "id": 509,
      "module": "Patient Cases - Master",
      "field": "nFacilityID",
      "type": "numeric",
      "description": "Facility Identifier"
    },
    {
      "id": 510,
      "module": "Patient Cases - Master",
      "field": "nReferralID",
      "type": "numeric",
      "description": "Referral Identifier"
    },
    {
      "id": 511,
      "module": "Patient Cases - Master",
      "field": "nAuthorizationID",
      "type": "numeric",
      "description": "Authorization Identifier"
    },
    {
      "id": 512,
      "module": "Patient Cases - Master",
      "field": "sAuthorizationNumber",
      "type": "text",
      "description": "Authorization Number"
    },
    {
      "id": 513,
      "module": "Patient Cases - Master",
      "field": "nReportCategoryId",
      "type": "numeric",
      "description": "Report Category Identifier"
    },
    {
      "id": 514,
      "module": "Patient Cases - Master",
      "field": "nAccidentType",
      "type": "numeric",
      "description": "Accident Type"
    },
    {
      "id": 515,
      "module": "Patient Cases - Master",
      "field": "Injury Date",
      "type": "date",
      "description": "Injury Date"
    },
    {
      "id": 516,
      "module": "Patient Cases - Master",
      "field": "Worker Comp #",
      "type": "text",
      "description": "Worker Comp Number"
    },
    {
      "id": 517,
      "module": "Patient Cases - Master",
      "field": "WCB Case #",
      "type": "text",
      "description": "WCB Case Number"
    },
    {
      "id": 518,
      "module": "Patient Cases - Master",
      "field": "Other Claim Date",
      "type": "date",
      "description": "Other Claim Date"
    },
    {
      "id": 519,
      "module": "Patient Cases - Master",
      "field": "Other Claim Date Qualifier",
      "type": "text",
      "description": "Other Claim Date Qualifier"
    },
    {
      "id": 520,
      "module": "Patient Cases - Master",
      "field": "Unbale TO Work From",
      "type": "date",
      "description": "Unbale TO Work From date"
    },
    {
      "id": 521,
      "module": "Patient Cases - Master",
      "field": "Unbale TO Work Till",
      "type": "date",
      "description": "Unbale TO Work Till date"
    },
    {
      "id": 522,
      "module": "Patient Cases - Master",
      "field": "HopitalizationDate From",
      "type": "date",
      "description": "Hopitalization Date From"
    },
    {
      "id": 523,
      "module": "Patient Cases - Master",
      "field": "HopitalizationDate To",
      "type": "date",
      "description": "Hopitalization Date To"
    },
    {
      "id": 524,
      "module": "Patient Cases - Master",
      "field": "nICDRevision",
      "type": "numeric",
      "description": "ICD Revision"
    },
    {
      "id": 525,
      "module": "Patient Cases - Master",
      "field": "Update Diagnoses from last Charge",
      "type": "boolean",
      "description": "Update Diagnoses from last Charge"
    },
    {
      "id": 526,
      "module": "Patient Cases - Master",
      "field": "sNote",
      "type": "text",
      "description": "Note"
    },
    {
      "id": 527,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nPriorAuthorizationID",
      "type": "numeric",
      "description": "Prior Authorization Identifier"
    },
    {
      "id": 528,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "sPriorAuthorizationNo",
      "type": "text",
      "description": "Prior Authorization Number"
    },
    {
      "id": 529,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nPatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 530,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nReferralID",
      "type": "numeric",
      "description": "Referral Identifier"
    },
    {
      "id": 532,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "bIsTrackAuthLimit",
      "type": "boolean",
      "description": "Is Track Authorization Limit"
    },
    {
      "id": 531,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nToReferralID",
      "type": "numeric",
      "description": "To Referral Identifier"
    },
    {
      "id": 533,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nStartDate",
      "type": "date",
      "description": "Start Date"
    },
    {
      "id": 534,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nExpDate",
      "type": "date",
      "description": "Exp Date"
    },
    {
      "id": 535,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nVisitsAllowed",
      "type": "numeric",
      "description": "Visits Allowed"
    },
    {
      "id": 536,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nInsuranceID",
      "type": "numeric",
      "description": "Insurance Identifier"
    },
    {
      "id": 537,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "sInsuranceName",
      "type": "text",
      "description": "Insurance Name"
    },
    {
      "id": 538,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nAuthorizationType",
      "type": "numeric",
      "description": "Authorization Type"
    },
    {
      "id": 539,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "sAuthorizationNote",
      "type": "text",
      "description": "Authorization Note"
    },
    {
      "id": 540,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "bIsActive",
      "type": "boolean",
      "description": "Is Active"
    },
    {
      "id": 541,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "dtCreatedDateTime",
      "type": "date",
      "description": "Created Date Time"
    },
    {
      "id": 542,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "dtModifiedDateTime",
      "type": "date",
      "description": "Modified Date Time"
    },
    {
      "id": 543,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "sCreatedUserID",
      "type": "numeric",
      "description": "Created User Identifier"
    },
    {
      "id": 544,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "sModifyUserID",
      "type": "numeric",
      "description": "Modify User Identifier"
    },
    {
      "id": 545,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "nClinicID",
      "type": "numeric",
      "description": "Clinic Identifier"
    },
    {
      "id": 546,
      "module": "Patient Cases - Prior Authorization Master",
      "field": "sVersionno",
      "type": "text",
      "description": "Version Number"
    },
    {
      "id": 547,
      "module": "Patient-Provider Messages",
      "field": "Date",
      "type": "date",
      "description": "Date of Message"
    },
    {
      "id": 548,
      "module": "Patient-Provider Messages",
      "field": "Message",
      "type": "text",
      "description": "Message"
    },
    {
      "id": 549,
      "module": "Patient-Provider Messages",
      "field": "Subject",
      "type": "text",
      "description": "Subject"
    },
    {
      "id": 550,
      "module": "Pharmacy",
      "field": "ContactType",
      "type": "text",
      "description": "Contact Type"
    },
    {
      "id": 551,
      "module": "Pharmacy",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 552,
      "module": "Pharmacy",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 553,
      "module": "Pharmacy",
      "field": "PharmacyName",
      "type": "text",
      "description": "Pharmacy Name"
    },
    {
      "id": 554,
      "module": "Pharmacy",
      "field": "Contact",
      "type": "text",
      "description": "Contact"
    },
    {
      "id": 555,
      "module": "Pharmacy",
      "field": "AddressLine1",
      "type": "text",
      "description": "Address Line 1"
    },
    {
      "id": 556,
      "module": "Pharmacy",
      "field": "AddressLine2",
      "type": "text",
      "description": "Address Line 2"
    },
    {
      "id": 557,
      "module": "Pharmacy",
      "field": "City",
      "type": "text",
      "description": "City"
    },
    {
      "id": 558,
      "module": "Pharmacy",
      "field": "State",
      "type": "text",
      "description": "State"
    },
    {
      "id": 559,
      "module": "Pharmacy",
      "field": "ZIP",
      "type": "text",
      "description": "ZIP"
    },
    {
      "id": 560,
      "module": "Pharmacy",
      "field": "County",
      "type": "text",
      "description": "County"
    },
    {
      "id": 561,
      "module": "Pharmacy",
      "field": "Country",
      "type": "text",
      "description": "Country"
    },
    {
      "id": 562,
      "module": "Pharmacy",
      "field": "Phone",
      "type": "text",
      "description": "Phone"
    },
    {
      "id": 563,
      "module": "Pharmacy",
      "field": "Fax",
      "type": "text",
      "description": "Fax"
    },
    {
      "id": 564,
      "module": "Pharmacy",
      "field": "Email",
      "type": "text",
      "description": "Email"
    },
    {
      "id": 565,
      "module": "Pharmacy",
      "field": "URL",
      "type": "text",
      "description": "URL"
    },
    {
      "id": 566,
      "module": "Pharmacy",
      "field": "Mobile",
      "type": "text",
      "description": "Mobile"
    },
    {
      "id": 567,
      "module": "Pharmacy",
      "field": "Pager",
      "type": "text",
      "description": "Pager"
    },
    {
      "id": 568,
      "module": "Pharmacy",
      "field": "Notes",
      "type": "text",
      "description": "Notes"
    },
    {
      "id": 569,
      "module": "Pharmacy",
      "field": "HospitalAffiliation",
      "type": "text",
      "description": "Hospital Affiliation"
    },
    {
      "id": 570,
      "module": "Pharmacy",
      "field": "ActiveStartTime",
      "type": "date",
      "description": "Active Start Time"
    },
    {
      "id": 571,
      "module": "Pharmacy",
      "field": "ActiveEndTime",
      "type": "date",
      "description": "Active End Time"
    },
    {
      "id": 572,
      "module": "Pharmacy",
      "field": "ContactStatus",
      "type": "numeric",
      "description": "Contact Status"
    },
    {
      "id": 573,
      "module": "Pharmacy",
      "field": "NCPDPID",
      "type": "text",
      "description": "NCPDP Identifier"
    },
    {
      "id": 574,
      "module": "Pharmacy",
      "field": "PharmacyStatus",
      "type": "text",
      "description": "Pharmacy Status"
    },
    {
      "id": 575,
      "module": "Pharmacy",
      "field": "ServiceLevel",
      "type": "text",
      "description": "Service Level"
    },
    {
      "id": 576,
      "module": "Primary Care Physician",
      "field": "ContactType",
      "type": "text",
      "description": "Contact Type"
    },
    {
      "id": 577,
      "module": "Primary Care Physician",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 578,
      "module": "Primary Care Physician",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 579,
      "module": "Primary Care Physician",
      "field": "Prefix",
      "type": "text",
      "description": "Prefix"
    },
    {
      "id": 580,
      "module": "Primary Care Physician",
      "field": "FirstName",
      "type": "text",
      "description": "First Name"
    },
    {
      "id": 581,
      "module": "Primary Care Physician",
      "field": "MiddleName",
      "type": "text",
      "description": "Middle Name"
    },
    {
      "id": 582,
      "module": "Primary Care Physician",
      "field": "LastName",
      "type": "text",
      "description": "Last Name"
    },
    {
      "id": 583,
      "module": "Primary Care Physician",
      "field": "Suffix",
      "type": "text",
      "description": "Suffix"
    },
    {
      "id": 584,
      "module": "Primary Care Physician",
      "field": "Degree",
      "type": "text",
      "description": "Degree"
    },
    {
      "id": 585,
      "module": "Primary Care Physician",
      "field": "Gender",
      "type": "text",
      "description": "Gender"
    },
    {
      "id": 586,
      "module": "Primary Care Physician",
      "field": "AddressLine1",
      "type": "text",
      "description": "Address Line 1"
    },
    {
      "id": 587,
      "module": "Primary Care Physician",
      "field": "AddressLine2",
      "type": "text",
      "description": "Address Line 2"
    },
    {
      "id": 588,
      "module": "Primary Care Physician",
      "field": "City",
      "type": "text",
      "description": "City"
    },
    {
      "id": 589,
      "module": "Primary Care Physician",
      "field": "State",
      "type": "text",
      "description": "State"
    },
    {
      "id": 590,
      "module": "Primary Care Physician",
      "field": "ZIP",
      "type": "text",
      "description": "ZIP"
    },
    {
      "id": 591,
      "module": "Primary Care Physician",
      "field": "County",
      "type": "text",
      "description": "County"
    },
    {
      "id": 592,
      "module": "Primary Care Physician",
      "field": "Country",
      "type": "text",
      "description": "Country"
    },
    {
      "id": 593,
      "module": "Primary Care Physician",
      "field": "Phone",
      "type": "text",
      "description": "Phone"
    },
    {
      "id": 594,
      "module": "Primary Care Physician",
      "field": "Fax",
      "type": "text",
      "description": "Fax"
    },
    {
      "id": 595,
      "module": "Primary Care Physician",
      "field": "Email",
      "type": "text",
      "description": "Email"
    },
    {
      "id": 596,
      "module": "Primary Care Physician",
      "field": "URL",
      "type": "text",
      "description": "URL"
    },
    {
      "id": 597,
      "module": "Primary Care Physician",
      "field": "Mobile",
      "type": "text",
      "description": "Mobile"
    },
    {
      "id": 598,
      "module": "Primary Care Physician",
      "field": "Pager",
      "type": "text",
      "description": "Pager"
    },
    {
      "id": 599,
      "module": "Primary Care Physician",
      "field": "Notes",
      "type": "text",
      "description": "Notes"
    },
    {
      "id": 600,
      "module": "Primary Care Physician",
      "field": "Taxonomy",
      "type": "text",
      "description": "Taxonomy"
    },
    {
      "id": 601,
      "module": "Primary Care Physician",
      "field": "TaxonomyDesc",
      "type": "text",
      "description": "Taxonomy Description"
    },
    {
      "id": 602,
      "module": "Primary Care Physician",
      "field": "TaxID",
      "type": "text",
      "description": "Tax Identifier"
    },
    {
      "id": 603,
      "module": "Primary Care Physician",
      "field": "UPIN",
      "type": "text",
      "description": "UPIN"
    },
    {
      "id": 604,
      "module": "Primary Care Physician",
      "field": "NPI",
      "type": "text",
      "description": "NPI"
    },
    {
      "id": 605,
      "module": "Primary Care Physician",
      "field": "HospitalAffiliation",
      "type": "text",
      "description": "Hospital Affiliation"
    },
    {
      "id": 606,
      "module": "Primary Care Physician",
      "field": "ActiveStartTime",
      "type": "date",
      "description": "Active Start Time"
    },
    {
      "id": 607,
      "module": "Primary Care Physician",
      "field": "ActiveEndTime",
      "type": "date",
      "description": "Active End Time"
    },
    {
      "id": 608,
      "module": "Primary Care Physician",
      "field": "ContactStatus",
      "type": "numeric",
      "description": "Contact Status"
    },
    {
      "id": 609,
      "module": "Prior Authorization",
      "field": "Patient Code",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 610,
      "module": "Prior Authorization",
      "field": "Patient Name",
      "type": "text",
      "description": "Patient Name"
    },
    {
      "id": 611,
      "module": "Prior Authorization",
      "field": "PriorAuthorizationID",
      "type": "numeric",
      "description": "Prior Authorization Identifier"
    },
    {
      "id": 612,
      "module": "Prior Authorization",
      "field": "Prior Authorization No",
      "type": "text",
      "description": "Prior Authorization Number"
    },
    {
      "id": 613,
      "module": "Prior Authorization",
      "field": "Authorization Note",
      "type": "text",
      "description": "Authorization Note"
    },
    {
      "id": 614,
      "module": "Prior Authorization",
      "field": "ReferralID",
      "type": "numeric",
      "description": "Referral Identifier"
    },
    {
      "id": 615,
      "module": "Prior Authorization",
      "field": "Referring Provider",
      "type": "text",
      "description": "Referring Provider"
    },
    {
      "id": 616,
      "module": "Prior Authorization",
      "field": "Insurance Name",
      "type": "text",
      "description": "Insurance Name"
    },
    {
      "id": 617,
      "module": "Prior Authorization",
      "field": "Insurance Note",
      "type": "text",
      "description": "Insurance Note"
    },
    {
      "id": 618,
      "module": "Prior Authorization",
      "field": "Track Auth Limits",
      "type": "boolean",
      "description": "Track Authorization Limits"
    },
    {
      "id": 619,
      "module": "Prior Authorization",
      "field": "Start Date",
      "type": "date",
      "description": "Start Date"
    },
    {
      "id": 620,
      "module": "Prior Authorization",
      "field": "Expiration Date",
      "type": "date",
      "description": "Expiration Date"
    },
    {
      "id": 621,
      "module": "Prior Authorization",
      "field": "# Visits Allowed",
      "type": "numeric",
      "description": "Visits Allowed"
    },
    {
      "id": 622,
      "module": "Prior Authorization",
      "field": "Visits Remained",
      "type": "numeric",
      "description": "Visits Remained"
    },
    {
      "id": 623,
      "module": "Prior Authorization",
      "field": "Report on Claim",
      "type": "text",
      "description": "Report on Claim"
    },
    {
      "id": 624,
      "module": "Prior Authorization Master",
      "field": "nPriorAuthorizationID",
      "type": "numeric",
      "description": "Prior Authorization Identifier"
    },
    {
      "id": 625,
      "module": "Prior Authorization Master",
      "field": "sPriorAuthorizationNo",
      "type": "text",
      "description": "Prior Authorization Number"
    },
    {
      "id": 626,
      "module": "Prior Authorization Master",
      "field": "nPatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 627,
      "module": "Prior Authorization Master",
      "field": "nReferralID",
      "type": "numeric",
      "description": "Referral Identifier"
    },
    {
      "id": 628,
      "module": "Prior Authorization Master",
      "field": "nToReferralID",
      "type": "numeric",
      "description": "To Referral Identifier"
    },
    {
      "id": 629,
      "module": "Prior Authorization Master",
      "field": "bIsTrackAuthLimit",
      "type": "boolean",
      "description": "Is Track Authorization Limit"
    },
    {
      "id": 630,
      "module": "Prior Authorization Master",
      "field": "nStartDate",
      "type": "date",
      "description": "Start Date"
    },

    {
      "id": 631,
      "module": "Prior Authorization Master",
      "field": "nExpDate",
      "type": "date",
      "description": "Expiry Date"
    },
    {
      "id": 632,
      "module": "Prior Authorization Master",
      "field": "nVisitsAllowed",
      "type": "numeric",
      "description": "Visits Allowed"
    },
    {
      "id": 636,
      "module": "Prior Authorization Master",
      "field": "sAuthorizationNote",
      "type": "text",
      "description": "Authorization Note"
    },
    {
      "id": 633,
      "module": "Prior Authorization Master",
      "field": "nInsuranceID",
      "type": "numeric",
      "description": "Insurance Identifier"
    },
    {
      "id": 634,
      "module": "Prior Authorization Master",
      "field": "sInsuranceName",
      "type": "text",
      "description": "Insurance Name"
    },
    {
      "id": 635,
      "module": "Prior Authorization Master",
      "field": "nAuthorizationType",
      "type": "numeric",
      "description": "Authorization Type"
    },
    {
      "id": 637,
      "module": "Prior Authorization Master",
      "field": "bIsActive",
      "type": "boolean",
      "description": "Is Active"
    },
    {
      "id": 638,
      "module": "Prior Authorization Master",
      "field": "dtCreatedDateTime",
      "type": "date",
      "description": "Created Date"
    },
    {
      "id": 639,
      "module": "Prior Authorization Master",
      "field": "dtModifiedDateTime",
      "type": "date",
      "description": "Modified date"
    },
    {
      "id": 640,
      "module": "Prior Authorization Master",
      "field": "sCreatedUserID",
      "type": "numeric",
      "description": "Created User Identifier"
    },
    {
      "id": 641,
      "module": "Prior Authorization Master",
      "field": "sModifyUserID",
      "type": "numeric",
      "description": "Modify User Identifier"
    },
    {
      "id": 642,
      "module": "Prior Authorization Master",
      "field": "nClinicID",
      "type": "numeric",
      "description": "Clinic Identifier"
    },
    {
      "id": 643,
      "module": "Prior Authorization Master",
      "field": "sVersionno",
      "type": "text",
      "description": "Version Number"
    },
    {
      "id": 644,
      "module": "Problems",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 645,
      "module": "Problems",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 646,
      "module": "Problems",
      "field": "VisitID",
      "type": "numeric",
      "description": "Visit Identifier"
    },
    {
      "id": 647,
      "module": "Problems",
      "field": "VisitDateTime",
      "type": "date",
      "description": "Visit Date Time"
    },
    {
      "id": 648,
      "module": "Problems",
      "field": "ProblemID",
      "type": "numeric",
      "description": "Problem Identifier"
    },
    {
      "id": 649,
      "module": "Problems",
      "field": "DateOfService",
      "type": "date",
      "description": "Date of Service"
    },
    {
      "id": 650,
      "module": "Problems",
      "field": "ICD9Code",
      "type": "text",
      "description": "ICD9 Code"
    },
    {
      "id": 651,
      "module": "Problems",
      "field": "ICD9Description",
      "type": "text",
      "description": "ICD9 Description"
    },
    {
      "id": 652,
      "module": "Problems",
      "field": "CheifComplaint",
      "type": "text",
      "description": "Cheif Complaint"
    },
    {
      "id": 653,
      "module": "Problems",
      "field": "ProblemStatus",
      "type": "numeric",
      "description": "Problem Status"
    },
    {
      "id": 654,
      "module": "Problems",
      "field": "Prescription",
      "type": "text",
      "description": "Prescription"
    },
    {
      "id": 655,
      "module": "Problems",
      "field": "ResolvedDate",
      "type": "date",
      "description": "Resolved Date"
    },
    {
      "id": 656,
      "module": "Problems",
      "field": "ResolvedComment",
      "type": "text",
      "description": "Resolved Comment"
    },
    {
      "id": 657,
      "module": "Problems",
      "field": "Immediacy",
      "type": "numeric",
      "description": "Immediacy"
    },
    {
      "id": 658,
      "module": "Problems",
      "field": "Comments",
      "type": "text",
      "description": "Comments"
    },
    {
      "id": 659,
      "module": "Problems",
      "field": "ConceptID",
      "type": "text",
      "description": "Concept Identifier"
    },
    {
      "id": 660,
      "module": "Problems",
      "field": "SnoMedID",
      "type": "text",
      "description": "SnoMed Identifier"
    },
    {
      "id": 661,
      "module": "Problems",
      "field": "DescriptionID",
      "type": "text",
      "description": " Description Identifier"
    },
    {
      "id": 662,
      "module": "Problems",
      "field": "Description",
      "type": "text",
      "description": "Description"
    },
    {
      "id": 663,
      "module": "Problems",
      "field": "TransactionID1",
      "type": "text",
      "description": "Transaction Identifier 1"
    },
    {
      "id": 664,
      "module": "Problems",
      "field": "TransactionID2",
      "type": "text",
      "description": "Transaction Identifier 2"
    },
    {
      "id": 665,
      "module": "Referrals",
      "field": "ContactType",
      "type": "text",
      "description": "Contact Type"
    },
    {
      "id": 666,
      "module": "Referrals",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 667,
      "module": "Referrals",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 668,
      "module": "Referrals",
      "field": "Prefix",
      "type": "text",
      "description": "Prefix"
    },
    {
      "id": 669,
      "module": "Referrals",
      "field": "FirstName",
      "type": "text",
      "description": "First Name"
    },
    {
      "id": 670,
      "module": "Referrals",
      "field": "MiddleName",
      "type": "text",
      "description": "Middle Name"
    },
    {
      "id": 671,
      "module": "Referrals",
      "field": "LastName",
      "type": "text",
      "description": "Last Name"
    },
    {
      "id": 672,
      "module": "Referrals",
      "field": "Suffix",
      "type": "text",
      "description": "Suffix"
    },
    {
      "id": 673,
      "module": "Referrals",
      "field": "Degree",
      "type": "text",
      "description": "Degree"
    },
    {
      "id": 674,
      "module": "Referrals",
      "field": "Gender",
      "type": "text",
      "description": "Gender"
    },
    {
      "id": 675,
      "module": "Referrals",
      "field": "AddressLine1",
      "type": "text",
      "description": "Address Line 1"
    },
    {
      "id": 676,
      "module": "Referrals",
      "field": "AddressLine2",
      "type": "text",
      "description": "Address Line 2"
    },
    {
      "id": 677,
      "module": "Referrals",
      "field": "City",
      "type": "text",
      "description": "City"
    },
    {
      "id": 678,
      "module": "Referrals",
      "field": "State",
      "type": "text",
      "description": "State"
    },
    {
      "id": 679,
      "module": "Referrals",
      "field": "ZIP",
      "type": "text",
      "description": "ZIP"
    },
    {
      "id": 680,
      "module": "Referrals",
      "field": "County",
      "type": "text",
      "description": "County"
    },
    {
      "id": 681,
      "module": "Referrals",
      "field": "Country",
      "type": "text",
      "description": "Country"
    },
    {
      "id": 682,
      "module": "Referrals",
      "field": "Phone",
      "type": "text",
      "description": "Phone"
    },
    {
      "id": 683,
      "module": "Referrals",
      "field": "Fax",
      "type": "text",
      "description": "Fax"
    },
    {
      "id": 684,
      "module": "Referrals",
      "field": "Email",
      "type": "text",
      "description": "Email"
    },
    {
      "id": 685,
      "module": "Referrals",
      "field": "URL",
      "type": "text",
      "description": "URL"
    },
    {
      "id": 686,
      "module": "Referrals",
      "field": "Mobile",
      "type": "text",
      "description": "Mobile"
    },
    {
      "id": 687,
      "module": "Referrals",
      "field": "Pager",
      "type": "text",
      "description": "Pager"
    },
    {
      "id": 688,
      "module": "Referrals",
      "field": "Notes",
      "type": "text",
      "description": "Notes"
    },
    {
      "id": 689,
      "module": "Referrals",
      "field": "Taxonomy",
      "type": "text",
      "description": "Taxonomy"
    },
    {
      "id": 690,
      "module": "Referrals",
      "field": "TaxonomyDesc",
      "type": "text",
      "description": "Taxonomy Description"
    },
    {
      "id": 691,
      "module": "Referrals",
      "field": "TaxID",
      "type": "text",
      "description": "Tax Identifier"
    },
    {
      "id": 692,
      "module": "Referrals",
      "field": "UPIN",
      "type": "text",
      "description": "UPIN"
    },
    {
      "id": 693,
      "module": "Referrals",
      "field": "NPI",
      "type": "text",
      "description": "NPI"
    },
    {
      "id": 694,
      "module": "Referrals",
      "field": "HospitalAffiliation",
      "type": "text",
      "description": "Hospital Affiliation"
    },
    {
      "id": 695,
      "module": "Referrals",
      "field": "ActiveStartTime",
      "type": "date",
      "description": "Active Start Time"
    },
    {
      "id": 696,
      "module": "Referrals",
      "field": "ActiveEndTime",
      "type": "date",
      "description": "Active End Time "
    },
    {
      "id": 697,
      "module": "Referrals",
      "field": "ContactStatus",
      "type": "numeric",
      "description": "Contact Status"
    },
    {
      "id": 698,
      "module": "Refunds",
      "field": "Patient",
      "type": "text",
      "description": "Patient Name"
    },
    {
      "id": 699,
      "module": "Refunds",
      "field": "CloseDate",
      "type": "date",
      "description": "Close Date"
    },
    {
      "id": 700,
      "module": "Refunds",
      "field": "Tray",
      "type": "text",
      "description": "Tray"
    },
    {
      "id": 701,
      "module": "Refunds",
      "field": "To",
      "type": "text",
      "description": "To"
    },
    {
      "id": 702,
      "module": "Refunds",
      "field": "RefundDate",
      "type": "date",
      "description": "Refund Date"
    },
    {
      "id": 703,
      "module": "Refunds",
      "field": "Amount",
      "type": "numeric",
      "description": "Amount"
    },
    {
      "id": 704,
      "module": "Refunds",
      "field": "Note",
      "type": "text",
      "description": "Note"
    },
    {
      "id": 705,
      "module": "Refunds",
      "field": "User",
      "type": "text",
      "description": "User"
    },
    {
      "id": 706,
      "module": "Refunds",
      "field": "DateTime",
      "type": "date",
      "description": "Date Time"
    },
    {
      "id": 707,
      "module": "Refunds",
      "field": "Status",
      "type": "text",
      "description": "Status"
    },
    {
      "id": 708,
      "module": "Reserves",
      "field": "Patient",
      "type": "text",
      "description": " Patient Name"
    },
    {
      "id": 709,
      "module": "Reserves",
      "field": "CloseDate",
      "type": "date",
      "description": "Close Date"
    },
    {
      "id": 710,
      "module": "Reserves",
      "field": "OriginalPayment",
      "type": "text",
      "description": "Original Payment"
    },
    {
      "id": 711,
      "module": "Reserves",
      "field": "ToReserves",
      "type": "numeric",
      "description": "To Reserves"
    },
    {
      "id": 712,
      "module": "Reserves",
      "field": "Type",
      "type": "text",
      "description": "Type"
    },
    {
      "id": 713,
      "module": "Reserves",
      "field": "Note",
      "type": "text",
      "description": "Note"
    },
    {
      "id": 714,
      "module": "Reserves",
      "field": "Available",
      "type": "numeric",
      "description": "Available"
    },
    {
      "id": 715,
      "module": "Vitals",
      "field": "PatientID",
      "type": "numeric",
      "description": "Patient Identifier"
    },
    {
      "id": 716,
      "module": "Vitals",
      "field": "PatientCode",
      "type": "text",
      "description": "Patient Code"
    },
    {
      "id": 717,
      "module": "Vitals",
      "field": "VitalID",
      "type": "numeric",
      "description": "Vital Identifier"
    },
    {
      "id": 718,
      "module": "Vitals",
      "field": "VisitID",
      "type": "numeric",
      "description": "Visit Identifier"
    },
    {
      "id": 719,
      "module": "Vitals",
      "field": "VisitDateTime",
      "type": "date",
      "description": "Visit Date Time"
    },
    {
      "id": 720,
      "module": "Vitals",
      "field": "VitalDate",
      "type": "date",
      "description": "Vital Date"
    },
    {
      "id": 721,
      "module": "Vitals",
      "field": "Height",
      "type": "text",
      "description": "Height"
    },
    {
      "id": 722,
      "module": "Vitals",
      "field": "Weightinlbs",
      "type": "numeric",
      "description": "Weight in lbs"
    },
    {
      "id": 723,
      "module": "Vitals",
      "field": "WeightChange",
      "type": "numeric",
      "description": "Weight Change"
    },
    {
      "id": 724,
      "module": "Vitals",
      "field": "BMI",
      "type": "numeric",
      "description": "BMI"
    },
    {
      "id": 725,
      "module": "Vitals",
      "field": "WeightinKg",
      "type": "numeric",
      "description": "Weight in Kg"
    },
    {
      "id": 726,
      "module": "Vitals",
      "field": "Temperature",
      "type": "numeric",
      "description": "Temperature"
    },
    {
      "id": 727,
      "module": "Vitals",
      "field": "RespiratoryRate",
      "type": "numeric",
      "description": "Respiratory Rate"
    },
    {
      "id": 728,
      "module": "Vitals",
      "field": "PulsePerMinute",
      "type": "numeric",
      "description": "Pulse Per Minute"
    },
    {
      "id": 729,
      "module": "Vitals",
      "field": "PulseOx",
      "type": "numeric",
      "description": "Pulse Ox"
    },
    {
      "id": 730,
      "module": "Vitals",
      "field": "BloodPressureSittingMin",
      "type": "numeric",
      "description": "Blood Pressure Sitting Minimum"
    },
    {
      "id": 731,
      "module": "Vitals",
      "field": "BloodPressureSittingMax",
      "type": "numeric",
      "description": "Blood Pressure Sitting Maximum"
    },
    {
      "id": 732,
      "module": "Vitals",
      "field": "BloodPressureStandingMin",
      "type": "numeric",
      "description": "Blood Pressure Standing Minimum"
    },
    {
      "id": 733,
      "module": "Vitals",
      "field": "BloodPressureStandingMax",
      "type": "numeric",
      "description": "Blood Pressure Standing Maximum"
    },
    {
      "id": 734,
      "module": "Vitals",
      "field": "HeadCircumferance",
      "type": "numeric",
      "description": "Head Circumferance"
    },
    {
      "id": 735,
      "module": "Vitals",
      "field": "Stature",
      "type": "numeric",
      "description": "Stature"
    },
    {
      "id": 736,
      "module": "Vitals",
      "field": "Comments",
      "type": "text",
      "description": "Comments"
    },
    {
      "id": 737,
      "module": "Vitals",
      "field": "THR",
      "type": "numeric",
      "description": "THR"
    },
    {
      "id": 738,
      "module": "Vitals",
      "field": "THRperMin",
      "type": "numeric",
      "description": "THR per Minimum"
    },
    {
      "id": 739,
      "module": "Vitals",
      "field": "THRperMax",
      "type": "numeric",
      "description": "THR per Maximum"
    },
    {
      "id": 740,
      "module": "Vitals",
      "field": "THRMin",
      "type": "numeric",
      "description": "THR Minimum"
    },
    {
      "id": 741,
      "module": "Vitals",
      "field": "THRMax",
      "type": "numeric",
      "description": "THR Maximum"
    },
    {
      "id": 742,
      "module": "Vitals",
      "field": "HeightinInch",
      "type": "numeric",
      "description": "Height in Inch"
    },
    {
      "id": 743,
      "module": "Vitals",
      "field": "HeightinCm",
      "type": "numeric",
      "description": "Height in Cm"
    },
    {
      "id": 744,
      "module": "Vitals",
      "field": "WeightinLbsOz",
      "type": "text",
      "description": "Weight in LbsOz"
    },
    {
      "id": 745,
      "module": "Vitals",
      "field": "TemperatureinCelcius",
      "type": "numeric",
      "description": "Temperature in Celcius"
    },
    {
      "id": 746,
      "module": "Vitals",
      "field": "PainLevel",
      "type": "numeric",
      "description": "Pain Level"
    },
    {
      "id": 747,
      "module": "Vitals",
      "field": "PEFR1",
      "type": "numeric",
      "description": "Peak Expiratory Flow Rate 1"
    },
    {
      "id": 748,
      "module": "Vitals",
      "field": "PEFR2",
      "type": "numeric",
      "description": "Peak Expiratory Flow Rate 2"
    },
    {
      "id": 749,
      "module": "Vitals",
      "field": "PEFR3",
      "type": "numeric",
      "description": "Peak Expiratory Flow Rate 3"
    },
    {
      "id": 750,
      "module": "Vitals",
      "field": "HeadCircumferenceInInch",
      "type": "numeric",
      "description": "Head Circumference In Inch"
    },
    {
      "id": 751,
      "module": "Vitals",
      "field": "StatureInInch",
      "type": "numeric",
      "description": "Stature In Inch"
    },
    {
      "id": 752,
      "module": "Vitals",
      "field": "SiteForBloodPressure",
      "type": "text",
      "description": "Site For Blood Pressure"
    },
    {
      "id": 753,
      "module": "Vitals",
      "field": "LastMenstrualPeriod",
      "type": "date",
      "description": "Last Menstrual Period"
    },
    {
      "id": 754,
      "module": "Vitals",
      "field": "NeckCircumferenceInCm",
      "type": "numeric",
      "description": "Neck Circumference In Cm"
    },
    {
      "id": 755,
      "module": "Vitals",
      "field": "NeckCircumferenceInInch",
      "type": "numeric",
      "description": "Neck Circumference In Inch"
    },
    {
      "id": 756,
      "module": "Vitals",
      "field": "LeftEyePressure",
      "type": "numeric",
      "description": "Left Eye Pressure"
    },
    {
      "id": 757,
      "module": "Vitals",
      "field": "RightEyePressure",
      "type": "numeric",
      "description": "Right Eye Pressure"
    },
    {
      "id": 758,
      "module": "Vitals",
      "field": "StatusLMPeriod",
      "type": "boolean",
      "description": "Status LM Period"
    },
    {
      "id": 759,
      "module": "Vitals",
      "field": "PainLevelWithMedication",
      "type": "numeric",
      "description": "Pain Level With Medication"
    },
    {
      "id": 760,
      "module": "Vitals",
      "field": "PainLevelWithoutMedication",
      "type": "numeric",
      "description": "Pain Level Without Medication"
    },
    {
      "id": 761,
      "module": "Vitals",
      "field": "PainLevelWorst",
      "type": "numeric",
      "description": "Pain Level Worst"
    },
    {
      "id": 762,
      "module": "Vitals",
      "field": "ODIPercent",
      "type": "numeric",
      "description": "ODI Percent"
    }
  ]
}
