<nav class="navbar top-header" id="navbar-main" *ngIf="isRequrednavbar">
    <div class="container-fluid">
        <a class="navbar-brand img-block ms-2 me-0" [routerLink]="['/list']"> <!-- routerLink="/dashboard/users" -->
            <img src="../../assets/img/Triarq_logo.svg" alt="" width="100">
        </a>
        <!-- <div class="header-links">
            <div class="dropdown pointer">
                <div class="user-profile dropdown-toggle dropdown-toggle-split" id="userDropdown" data-bs-toggle="dropdown" 
                    aria-expanded="false">
                    <div class="user-icon">
                        <img src="assets/img/profile.jpg" alt="">
                    </div>
                    <div class="mx-3">
                        <h6 class="mb-0  login_name">John Due</h6>
                        <span class="designation">Provider</span>
                    </div>
                </div>
                <ul class="dropdown-menu" aria-labelledby="userDropdown">
                    <li>
                        <a class="dropdown-item" [routerLink]="['list']"><i class="bi-box-arrow-right me-2"></i> Sign out</a>
                    </li>
                </ul>
            </div>

        </div> -->
    </div>
</nav>
