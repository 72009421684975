<footer class="main-footer">
    <div class="container">
      <ul class="footer-links">
        <li><a href="https://triarqhealth.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
        <li><a routerLink="/api-terms-of-use">Terms Of Use</a></li>
      </ul>
      <div class="footer-info"> © Copyright TRIARQ Health 2023. All Rights Reserved.</div>
    </div>
  </footer>
  
  
  