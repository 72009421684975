import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FhirServiceService } from 'src/app/services/fhir-service.service';
@Component({
  selector: 'app-syntax',
  templateUrl: './syntax.component.html',
  styleUrls: ['./syntax.component.scss']
})
export class SyntaxComponent {
  public searchText = '';
  src: string = '';
  public resourceOperations = [];
  public loaderType = 'pulsing';
  public loadrethemeColor = 'primary';
  public loadersize = 'medium';
  public is_processing: boolean = false;
  public is_Loading: boolean = true;
  public is_HomeLayout: boolean = false;
  public is_OverviewLayout: boolean = false;
  public is_AuthenticationAuthorizationLayout: boolean = false;
  public is_addRegistrationLayout: boolean = false;
  public errorCode!: string;
  showFirst = true;
  Diagnosis: any[] = [];
  InsurancePlan: any[] = [];
  Master: any[] = [];
  PriorAuthorizationMst: any[] = [];
  resourceList: any;
  url = "https://fhir.myqone.com/resourcelist?src=init"
  selectedItem: any;
  resourceContent: any[] = [];
  description: any;
  searchResourceList: any;
  data: any[] = [];
  hide:boolean=true
  constructor(
    private route: ActivatedRoute,
    private fhirService: FhirServiceService
  ) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.selectedItem = params['ResourceType'];
      this.description = params['ResourceDescription'];
    })
    this.resourceList = this.fhirService.resourceList
    this.data = this.fhirService.ehiexportdocs
    this.searchData('');
    this.togglediv();
    this.EHIExportDocs();
  }

  EHIExportDocs() {
    this.resourceContent = [];
    this.Diagnosis = [];
    this.InsurancePlan = [];
    this.PriorAuthorizationMst = [];
    this.Master = [];
    if (this.selectedItem == 'Patient Cases') {
      for (var i = 0; i < this.data.length; i++) {
        if (this.data[i].module == 'Patient Cases - Diagnosis') {
          this.Diagnosis.push(this.data[i]);
        }
        if (this.data[i].module == 'Patient Cases - Insurance Plan') {
          this.InsurancePlan.push(this.data[i]);
        }
        if (this.data[i].module == 'Patient Cases - Master') {
          this.Master.push(this.data[i]);
        }
        if (this.data[i].module == 'Patient Cases - Prior Authorization Master') {
          this.PriorAuthorizationMst.push(this.data[i]);
        }
      }
    } else {
      for (var i = 0; i < this.data.length; i++) {
        if (this.data[i].module == this.selectedItem) {
          this.resourceContent.push(this.data[i])
        }
      }
    }
  }
  togglediv() {
    const element: any = document.getElementById('dropdown-menu');
    element.classList.toggle('togglemenu');
  }

  showResourceDetails(resource: any) {
    this.description = resource.description
    this.selectedItem = resource.module;
    this.EHIExportDocs()
  }

  getActiveClass(row: any) {
    return this.selectedItem == row ? 'active' : '';
  }

  searchData(searchValue: any) {
    if (!searchValue) {
      this.hide=true
      this.searchResourceList = this.resourceList
    } else {
      this.hide=false
      this.searchResourceList = this.resourceList.filter((item: any) => {
        return item.module.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
  }
  search(){
    this.hide=true
    this.searchData('')
  }
}

