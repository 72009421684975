import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FhirServiceService } from 'src/app/services/fhir-service.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  src!: '';
  resourceList: any[] = []
  public resourceOperations = ['Read'];
  constructor(private router: Router, private fhirService: FhirServiceService) {

  }
  ngOnInit(): void {
    this.resourceList = this.fhirService.resourceList
  }

  resourceDetails(ResourceType: any) {
    this.router.navigate(['/syntax'], {
      queryParams: { ResourceType: ResourceType.module, ResourceDescription: ResourceType.description, src: this.src },
    });
  }
}
