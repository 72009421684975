import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  patientName!: string;
  isRequrednavbar: boolean = true;
  flagset: boolean = true;
  signOutFlag: boolean = true;
  constructor(
    private router: Router,
  ) {

  }
  ngOnInit(): void {
   this.signOutFlag = false;
  }



}
